let createGetIdentidad = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (Documento, Sexo) => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${
      environment.creditRiskUrl
    }/personasfisicas/identidad?documento=${Documento}&sexo=${Sexo}`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export { createGetIdentidad };
