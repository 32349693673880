import { grey400, white, fullBlack } from "material-ui/styles/colors";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { fade } from "material-ui/utils/colorManipulator";
import spacing from "material-ui/styles/spacing";

import * as logoBlanco from "../images/mg-logo-white.png";
import * as logoColor from "../images/mg-logo-color.png";

import contactIconNuevo from "../images/solicitud-nuevo/contact-1.svg";
import autoIconNuevo from "../images/solicitud-nuevo/mg_group_ui_assets_auto.svg";
import contactoIconNuevo from "../images/solicitud-nuevo/mg_group_ui_assets_contacto.svg";

import conyugeIconNuevo from "../images/solicitud-nuevo/mg_group_ui_assets_conyuge.svg";
import direccionIconNuevo from "../images/solicitud-nuevo/mg_group_ui_assets_direccion.svg";
import identidadIconNuevo from "../images/solicitud-nuevo/mg_group_ui_assets_identidad.svg";
import seguroIconNuevo from "../images/solicitud-nuevo/mg_group_ui_assets_seguro.svg";
import volkswagen from "../images/cotizadorMarcas/volkswagen.svg";
import chevrolet from "../images/cotizadorMarcas/chevrolet.svg";
import ford from "../images/cotizadorMarcas/ford.svg";

const MGLogoBlanco = logoBlanco.default;
const MGLogoColor = logoColor.default;

const purple1 = "#663ea8"; /* "hsl(263, 46%, 45%)"; */
const purple2 = "hsl(260, 44%, 45%)";
const purple3 = "hsl(263, 45%, 35%)";
const purple4 = "hsl(231, 44%, 94%)";
const purple5 = "hsl(249, 20%, 55%)";
const purple6 = "#423d5c"; //"hsla(249, 20%, 30%, 1)";
const purple7 = "hsl(241, 46%, 65%)";
const purple8 = "hsla(240, 40%, 99%, 1)";
const purple9 = "hsla(240, 58%, 85%, 0.30)";
const purple10 = "hsl(256, 22%, 43%)";
const purple11 = "hsla(231, 42%, 95%, 0.98)";
const purple12 = "hsl(252, 48%, 94%)";
const purple13 = "#9f7cd8"; /* "hsl(263, 54%, 67%)"; */
const purple14 = "hsl(263, 46%, 45%)";
const purple15 = "#efebf6";

const cyan1 = "hsl(190, 63%, 57%)";
const cyan2 = "hsl(190, 52%, 65%)";
const cyan3 = "hsl(190, 63%, 85%)";
const cyan4 = "hsl(187, 62%, 95%)";
const cyan5 = "hsl(190, 65%, 25%)";
const cyan6 = "hsl(190, 69%, 68%)";
const cyan7 = "hsl(190, 84%, 42%)";

const red1 = "hsl(348, 100%, 35%)";
const red2 = "hsl(0, 100%, 94%)";
const red3 = "hsl(0, 52%, 29%)";
const red4 = "hsla(0, 100%, 94%, 0.36)";
const red5 = "hsla(0, 52%, 39%, 1)";

const grey1 = "hsl(0, 0%, 98%)";
const grey2 = "hsl(0, 0%, 42%)";
const grey3 = "hsla(250, 20%, 30%, 0.7)";
const grey4 = "hsl(0, 0%, 50%)";
const grey5 = "hsl(0, 0%, 20%)";
const grey6 = "hsla(0, 0%, 0%, 0.54)";
const grey7 = "hsl(0, 0%, 91%)";
const grey8 = "hsl(300, 5%, 96%)";
const grey9 = "hsl(0, 0%, 87%)";
const grey10 = "hsl(216, 8%, 88%)";
const grey11 = "#9e9e9e"; /* "hsl(0, 0%, 62%)"; */
const grey12 = "hsl(216, 13%, 92%)";
const grey13 = "hsl(0, 0%, 26%)";
const grey14 = "hsl(200, 11%, 46%)";
const grey15 = "hsl(0, 0%, 93%)";
const grey16 = "#616161";
const grey17 = "#bdbdbd";

const green1 = "hsl(160, 48%, 46%)";
const green2 = "hsl(123, 51%, 85%)";
const green3 = "hsl(120, 35%, 92%)";
const green4 = "hsl(111, 54%, 87%)";
const green5 = "hsl(113, 50%, 13%)";
const green6 = "hsla(111, 47%, 94%, 0.33)";
const green7 = "hsl(173, 45%, 24%)";
const green8 = "hsl(138, 70%, 87%)";

const blue1 = "hsl(203, 63%, 54%)";
const blue2 = "hsl(216, 80%, 92%)";
const blue3 = "hsl(208, 56%, 46%)";

const yellow1 = "hsl(46, 81%, 88%)";
const yellow2 = "hsl(19, 56%, 25%)";
const yellow3 = "hsla(67, 62%, 92%, 1)";
const yellow4 = "hsla(55, 70%, 83%, 1)";

const muiMGTheme = getMuiTheme({
  icons: {
    contactIcon: contactIconNuevo,
    autoIcon: autoIconNuevo,
    contactoIcon: contactoIconNuevo,
    conyugeIcon: conyugeIconNuevo,
    direccionIcon: direccionIconNuevo,
    identidadIcon: identidadIconNuevo,
    seguroIcon: seguroIconNuevo,
    volkswagenIcon: volkswagen,
    chevroletIcon: chevrolet,
    fordIcon: ford
  },
  logoBlanco: MGLogoBlanco,
  logoColor: MGLogoColor,
  spacing: spacing,
  fontFamily: "Nunito, sans-serif",
  palette: {
    primary1Color: purple1,
    primary2Color: purple2,
    primary3Color: purple3,
    primary4Color: purple4,
    primary5Color: purple13,
    primary6Color: purple14,
    primary7Color: purple15,
    accent1Color: cyan1,
    accent2Color: cyan2,
    accent3Color: cyan3,
    accent4Color: cyan4,
    accent5Color: cyan5,
    accent6Color: cyan6,
    accent7Color: cyan7,
    textColor: fade(purple6, 0.9),
    alternateTextColor: white,
    canvasColor: grey1,
    borderColor: grey7,
    disabledColor: fade(purple6, 0.6),
    subtitleColor: purple5,
    tableHeaderColor: purple7,
    tableRowColor: purple8,
    tableBorderColor: grey8,
    tableTextColor: grey6,
    popupTextColor: grey3,
    popupTextBoldColor: grey2,
    hintColor: grey4,
    pickerHeaderColor: cyan2,
    cardBorderColor: grey10,
    cardHeaderColor: grey14,
    logColor: grey11,
    commentBorderColor: grey12,
    uncheckedIcon: grey13,
    disabledSecondaryColor: grey15,
    cardContentColor: grey16,
    disabledBtnLinkColor: grey17,
    bubble1Color: blue2,
    bubble2Color: purple12,
    clockCircleColor: fade(cyan2, 0.07),
    chipBackgroundColor: purple11,
    chipBorderColor: purple9,
    chipCrossColor: purple10,
    shadowColor: fullBlack,
    shadowLightColor: grey9,
    unreadColor: grey5,
    pendingColor: yellow1,
    pending2Color: yellow2,
    pending3Color: yellow3,
    pending4Color: yellow4,
    successColor: green1,
    success2Color: green2,
    success3Color: green3,
    success4Color: green4,
    success5Color: green5,
    success6Color: green6,
    success7Color: green7,
    success8Color: green8,
    errorColor: red1,
    error2Color: red2,
    error3Color: red3,
    error4Color: red4,
    error5Color: red5,
    linkColor: blue1,
    link2Color: blue3
  },
  appBar: {
    textColor: white,
    xPadding: 0,
    xMarginTop: 10
  },
  textField: {
    floatingLabelColor: grey400,
    floatingLabelShrinkColor: purple3
  }
});

export default muiMGTheme;
