// Prefijo RN_: mensaje originado por react native.
export const RN_IS_ON_NATIVE_APP = "RN_IS_ON_NATIVE_APP";
export const RN_DEVICE_INFO = "RN_DEVICE_INFO";
export const RN_SCAN_CEDULA = "RN_SCAN_CEDULA";
export const CLEAR_NATIVE_CEDULA = "CLEAR_NATIVE_CEDULA";
export const SHOW_NATIVE_POPUP = "SHOW_NATIVE_POPUP";
export const RN_SCAN_DNI = "RN_SCAN_DNI";
export const RN_NATIVE_CREDENTIALS = "RN_NATIVE_CREDENTIALS";

export const onNativeMessageReceived = eventData => {
  return eventData;
};

export const sendMessageToNative = message => {
  if (window.ReactNativeWebView)
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

export const showNativePopup = name => ({
  type: SHOW_NATIVE_POPUP,
  payload: {
    menuItem: name
  }
});

export const clearNativeCedula = name => ({
  type: CLEAR_NATIVE_CEDULA,
  payload: {}
});
