import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class FeatureFlagRender extends PureComponent {
  render() {
    if (this.props.when !== this.props.value) return null;

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

FeatureFlagRender.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  when: PropTypes.bool
};

FeatureFlagRender.defaultProps = {
  when: true
};

export default FeatureFlagRender;
