// configuration dependencies
import environment from "../environment";
import localRepository from "../localRepository";
import fetchRetry from "../libs/fetch-retry";

import {
  parseError,
  errorUnlessOk,
  buildRequest,
  createBuildRequestWithAuth
} from "./utils";

import {
  createPostChangeRequest,
  createGetChangeRequest
} from "./pedidoCambioApiFactories";

import { createGetTermsAndConditions } from "./tycApiFactories";

import {
  getProvinciasArgentinas,
  createGetProvinciasCodigosPostales,
  createGetProvinciasLocalidades
} from "./provinciasApiFactories";

import {
  createGetCanales,
  createPostReloadCanales
} from "./canalesApiFactories";

import {
  createGetInfoautoMarcas,
  createGetInfoautoModelos,
  createGetInfoautoCodiaPrecio,
  createGetInfoautoCodiaPrecioAbierto,
  createPostCapturaAuto,
  createPostCapturaAutoAsync,
  createGetCapturaAutoResult
} from "./autoApiFactories";

import { createGetContacto, createPostContacto } from "./contactoApiFactories";

import { createPostRegisterPushToken } from "./notificationsApiFactories";

import { createGetCalculadorResult } from "./calculadorApiFactories";

import {
  createPostAgregarCodeudor,
  createPostAgregarConyugeCodeudor,
  createPostAgregarConyuge,
  createPostSolicitudNueva,
  createPostContinuarSolicitud,
  createPostSolicitudIngresos,
  createPostSolicitudDomicilioTitular,
  createGetConfiguracionContactos,
  createPostSolicitudAuto,
  createPostSolicitudPreferenciaVehiculo,
  createPostSolicitudImporte,
  createGetSolicitudSegurosCotizaciones,
  createPostSolicitudSeguroCotizacion,
  createGetEstadoCalificacion,
  createGetOfertas,
  createPostSolicitudSeleccionOferta,
  createPostSolicitudCerrarSinOferta,
  createGetSolicitudes,
  createGetSolicitudById,
  createGetSolicitudSegurosInicio,
  createGetSolicitudSegurosResult,
  createGetPlazosPrestamo,
  createGetEstadoSolicitud
} from "./solicitudesApiFactories";

import {
  createGetTareasSolicitud,
  createGetTareaSolicitud,
  createPostTarea,
  createGetFetchArchivoTarea,
  createGetFetchArchivoTareaThumbnail,
  createPostArchivoTarea,
  createDeleteArchivoTarea,
  createPostTareaConyuge,
  createPostTareaCodeudor,
  createPostTareaTelefono
} from "./tareasApiFactories";

import { createGetIdentidad } from "./creditRiskApiFactories";

import {
  createGetSegurosCotizaciones,
  createPostSegurosCotizar,
  createPostSegurosQuieroEstaPoliza,
  createPostSegurosQuieroQueMeContacten
} from "./segurosFactories";

import {
  createPostSignupAgencia,
  createPostSingupAgenciaConfirmacion,
  createPostAgenciaUserPassword
} from "./signupAgenciasFactories";

import {
  createPostRecoverPassword,
  createSessionLogin,
  createSessionSendRecoveryPasswordEmail,
  createGetPublicIp
} from "./userApiFactories";

import {
  createGetAceptacion,
  createPostAceptarTerminos
} from "./terminosYCondicionesApiFactories";
// dependecies injection

const buildRequestWithAuth = createBuildRequestWithAuth({ localRepository });

const sessionLogin = createSessionLogin({
  fetch: window.fetch,
  environment,
  buildRequest,
  errorUnlessOk
});
const sessionSendRecoveryPasswordEmail = createSessionSendRecoveryPasswordEmail(
  { fetch: window.fetch, environment, buildRequest, errorUnlessOk }
);

let [
  getCanales,
  getInfoautoMarcas,
  getInfoautoModelos,
  getInfoautoCodiaPrecio,
  getInfoautoCodiaPrecioAbierto,
  postCapturaAuto,
  postCapturaAutoAsync,
  getCapturaAutoResult,
  getProvinciasCodigosPostales,
  getProvinciasLocalidades,
  getSolicitudSegurosCotizaciones,
  getIdentidad,
  postSolicitudNueva,
  postContinuarSolicitud,
  postSolicitudIngresos,
  postSolicitudDomicilioTitular,
  getConfiguracionContactos,
  postSolicitudImporte,
  postSolicitudAuto,
  postSolicitudPreferenciaVehiculo,
  postSolicitudSeguroCotizacion,
  getEstadoCalificacion,
  getOfertas,
  postSolicitudSeleccionOferta,
  postSolicitudCerrarSinOferta,
  getSolicitudes,
  getSolicitudById,
  getTermsAndConditions,
  getCalculadorResult,
  getContactoOffice,
  postContacto,
  postRegisterPushToken,
  getTareasSolicitud,
  getTareaSolicitud,
  postTarea,
  fetchArchivoTarea,
  fetchArchivoTareaThumbnail,
  postArchivoTarea,
  deleteArchivoTarea,
  postTareaConyuge,
  postTareaCodeudor,
  postTareaTelefono,
  postChangeRequest,
  postSignupAgencia,
  getPublicIp,
  postSingupAgenciaConfirmacion,
  postAgenciaUserPassword,
  postRecoverPassword,
  getchangeRequests,
  getSegurosCotizaciones,
  postSegurosCotizar,
  postSegurosQuieroEstaPoliza,
  postSegurosQuieroQueMeContacten,
  postReloadCanales,
  getSolicitudSegurosInicio,
  getSolicitudSegurosResult,
  postAgregarConyuge,
  postAgregarConyugeCodeudor,
  postAgregarCodeudor,
  getAceptacion,
  postAceptarTerminos,
  getPlazosPrestamo,
  getEstadoSolicitud
] = [
  createGetCanales,
  createGetInfoautoMarcas,
  createGetInfoautoModelos,
  createGetInfoautoCodiaPrecio,
  createGetInfoautoCodiaPrecioAbierto,
  createPostCapturaAuto,
  createPostCapturaAutoAsync,
  createGetCapturaAutoResult,
  createGetProvinciasCodigosPostales,
  createGetProvinciasLocalidades,
  createGetSolicitudSegurosCotizaciones,
  createGetIdentidad,
  createPostSolicitudNueva,
  createPostContinuarSolicitud,
  createPostSolicitudIngresos,
  createPostSolicitudDomicilioTitular,
  createGetConfiguracionContactos,
  createPostSolicitudImporte,
  createPostSolicitudAuto,
  createPostSolicitudPreferenciaVehiculo,
  createPostSolicitudSeguroCotizacion,
  createGetEstadoCalificacion,
  createGetOfertas,
  createPostSolicitudSeleccionOferta,
  createPostSolicitudCerrarSinOferta,
  createGetSolicitudes,
  createGetSolicitudById,
  createGetTermsAndConditions,
  createGetCalculadorResult,
  createGetContacto,
  createPostContacto,
  createPostRegisterPushToken,
  createGetTareasSolicitud,
  createGetTareaSolicitud,
  createPostTarea,
  createGetFetchArchivoTarea,
  createGetFetchArchivoTareaThumbnail,
  createPostArchivoTarea,
  createDeleteArchivoTarea,
  createPostTareaConyuge,
  createPostTareaCodeudor,
  createPostTareaTelefono,
  createPostChangeRequest,
  createPostSignupAgencia,
  createGetPublicIp,
  createPostSingupAgenciaConfirmacion,
  createPostAgenciaUserPassword,
  createPostRecoverPassword,
  createGetChangeRequest,
  createGetSegurosCotizaciones,
  createPostSegurosCotizar,
  createPostSegurosQuieroEstaPoliza,
  createPostSegurosQuieroQueMeContacten,
  createPostReloadCanales,
  createGetSolicitudSegurosInicio,
  createGetSolicitudSegurosResult,
  createPostAgregarConyuge,
  createPostAgregarConyugeCodeudor,
  createPostAgregarCodeudor,
  createGetAceptacion,
  createPostAceptarTerminos,
  createGetPlazosPrestamo,
  createGetEstadoSolicitud
].map(factory =>
  factory({
    fetch: fetchRetry,
    environment,
    buildRequestWithAuth,
    errorUnlessOk,
    parseError,
    localRepository
  })
);

export {
  sessionLogin,
  sessionSendRecoveryPasswordEmail,
  getProvinciasArgentinas,
  getCanales,
  getInfoautoMarcas,
  getInfoautoModelos,
  getInfoautoCodiaPrecio,
  getInfoautoCodiaPrecioAbierto,
  postCapturaAuto,
  postCapturaAutoAsync,
  getCapturaAutoResult,
  getProvinciasCodigosPostales,
  getProvinciasLocalidades,
  getSolicitudSegurosCotizaciones,
  getIdentidad,
  postSolicitudNueva,
  postContinuarSolicitud,
  postSolicitudIngresos,
  postSolicitudDomicilioTitular,
  getConfiguracionContactos,
  postSolicitudAuto,
  postSolicitudPreferenciaVehiculo,
  postSolicitudImporte,
  postSolicitudSeguroCotizacion,
  getEstadoCalificacion,
  getOfertas,
  postSolicitudSeleccionOferta,
  postSolicitudCerrarSinOferta,
  getSolicitudes,
  getSolicitudById,
  getTermsAndConditions,
  getCalculadorResult,
  getContactoOffice,
  postContacto,
  postRegisterPushToken,
  getTareasSolicitud,
  getTareaSolicitud,
  postTarea,
  fetchArchivoTarea,
  fetchArchivoTareaThumbnail,
  postArchivoTarea,
  deleteArchivoTarea,
  postTareaConyuge,
  postTareaCodeudor,
  postTareaTelefono,
  postChangeRequest,
  postSignupAgencia,
  getPublicIp, //revisar - no está en uso
  postSingupAgenciaConfirmacion,
  postAgenciaUserPassword,
  postRecoverPassword,
  getchangeRequests,
  getSegurosCotizaciones,
  postSegurosCotizar,
  postSegurosQuieroEstaPoliza,
  postSegurosQuieroQueMeContacten,
  postReloadCanales,
  getSolicitudSegurosInicio,
  getSolicitudSegurosResult,
  postAgregarConyuge,
  postAgregarConyugeCodeudor,
  postAgregarCodeudor,
  getAceptacion,
  postAceptarTerminos,
  getPlazosPrestamo,
  getEstadoSolicitud,
  buildRequestWithAuth
};
