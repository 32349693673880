import {
  DIRECCIONFORM_ISLOADING,
  DIRECCIONFORM_BLOCKEDFORM,
  DIRECCIONFORM_SELECTPROVINCIA,
  DIRECCIONFORM_SELECTLOCALIDAD,
  DIRECCIONFORM_SUBMITFORM,
  DIRECCIONFORM_INIT,
  DIRECCIONFORM_LOAD,
  DIRECCIONFORM_SELECTCODIGOPOSTAL
} from "./actions";

const initialState = {
  isLoading: false,
  disabled: false,
  streetAddress: {
    value: null
  },
  selectedProvince: null,
  selectedCity: null,
  selectedPostCode: null
};

const direccionFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case DIRECCIONFORM_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case DIRECCIONFORM_BLOCKEDFORM:
      return {
        ...state,
        disabled: action.payload.disabled
      };
    case DIRECCIONFORM_SELECTPROVINCIA:
      return {
        ...state,
        selectedProvince: action.payload.provinciaSelected,
        selectedCity: null,
        selectedPostCode: null
      };
    case DIRECCIONFORM_SELECTLOCALIDAD:
      return {
        ...state,
        selectedCity: action.payload.localidadSelected,
        selectedPostCode: null
      };
    case DIRECCIONFORM_SELECTCODIGOPOSTAL:
      return {
        ...state,
        selectedPostCode: action.payload.codigoPostalSelected
      };
    case DIRECCIONFORM_SUBMITFORM:
      return {
        ...state,
        streetAddress: {
          value: action.payload.Direccion
        }
      };
    case DIRECCIONFORM_LOAD:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    case DIRECCIONFORM_INIT:
      return initialState;
    default:
      return state;
  }
};

export default direccionFormReducer;
