export const SEGUROS_COTIZAR_VEHICULO_MARCA_SELECT =
  "SEGUROS_COTIZAR_VEHICULO_MARCA_SELECT";
export const SEGUROS_COTIZAR_VEHICULO_ANIO_SELECT =
  "SEGUROS_COTIZAR_VEHICULO_ANIO_SELECT";
export const SEGUROS_COTIZAR_VEHICULO_MODELO_SELECT =
  "SEGUROS_COTIZAR_VEHICULO_MODELO_SELECT";
export const SEGUROS_COTIZAR_VEHICULO_USO_SELECT =
  "SEGUROS_COTIZAR_VEHICULO_USO_SELECT";
export const SEGUROS_COTIZAR_VEHICULO_GNC_SELECT =
  "SEGUROS_COTIZAR_VEHICULO_GNC_SELECT";
export const SEGUROS_COTIZAR_PROVINCIA_SELECT =
  "SEGUROS_COTIZAR_PROVINCIA_SELECT";
export const SEGUROS_COTIZAR_CODIGO_POSTAL_SELECT =
  "SEGUROS_COTIZAR_CODIGO_POSTAL_SELECT";
export const SEGUROS_COTIZAR_SEXO_SELECT = "SEGUROS_COTIZAR_SEXO_SELECT";
export const SEGUROS_COTIZAR_ESTADO_CIVIL_SELECT =
  "SEGUROS_COTIZAR_ESTADO_CIVIL_SELECT";
export const SEGUROS_COTIZAR_FECHA_NAC_SELECT =
  "SEGUROS_COTIZAR_FECHA_NAC_SELECT";
export const SEGUROS_COTIZAR_FORMA_PAGO_SELECT =
  "SEGUROS_COTIZAR_FORMA_PAGO_SELECT";

export const segurosCotizarVehiculoMarcaSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_VEHICULO_MARCA_SELECT,
    payload: {
      vehiculoMarcaSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-marca-seleccionada",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarVehiculoAnioSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_VEHICULO_ANIO_SELECT,
    payload: {
      vehiculoAnioSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-anio-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarVehiculoModeloSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_VEHICULO_MODELO_SELECT,
    payload: {
      vehiculoModeloSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-modelo-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarFormaPagoSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_FORMA_PAGO_SELECT,
    payload: {
      formaPagoSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-forma-pago-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarVehiculoUsoSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_VEHICULO_USO_SELECT,
    payload: {
      vehiculoUsoSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-uso-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarVehiculoGNCSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_VEHICULO_GNC_SELECT,
    payload: {
      vehiculoGNCSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-gnc-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarProvinciaSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_PROVINCIA_SELECT,
    payload: {
      provinciaSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-provincia-seleccionada",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarCodigoPostalSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_CODIGO_POSTAL_SELECT,
    payload: {
      codigoPostalSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-codigo-postal-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarSexoSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_SEXO_SELECT,
    payload: {
      sexoSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-sexo-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarEstadoCivilSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_ESTADO_CIVIL_SELECT,
    payload: {
      estadoCivilSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-estado-civil-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};

export const segurosCotizarFechaNacimientoSelect = selected => {
  return {
    type: SEGUROS_COTIZAR_FECHA_NAC_SELECT,
    payload: {
      fechaNacimientoSelected: selected
    },
    analytics: {
      eventName: "cotizador-seguros-fecha-nacimiento-seleccionado",
      eventData: {
        ...selected
      }
    }
  };
};
