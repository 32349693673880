import {
  LOG_IN_ERROR,
  LOG_IN_SUCCESS,
  LOG_OUT,
  LOG_IN_ISAUTHENTICATED,
  LOG_IN_ISLOADING
} from "../componentes/user/js/actions";
import localRepository from "../localRepository";
import {
  logInUserAnalytics,
  logOutUserAnalytics
} from "../libs/amplitudeHelper";

const analyticsAuthMiddleware = store => next => action => {
  if (
    action.type === LOG_IN_ERROR ||
    action.type === LOG_IN_SUCCESS ||
    action.type === LOG_OUT ||
    action.type === LOG_IN_ISAUTHENTICATED ||
    action.type === LOG_IN_ISLOADING
  ) {
    let user = localRepository.isAuthenticated.get()
      ? localRepository.auth.get().user
      : null;

    console.debug("Analytics auth", user);

    if (user)
      logInUserAnalytics(
        user.email,
        user.preferred_username,
        user.sub,
        localRepository.version.get()
      );
    else {
      logOutUserAnalytics();
    }
  }

  return next(action);
};

export default analyticsAuthMiddleware;
