import {
  PRESTAMOFORM_INIT,
  PRESTAMOFORM_LOAD,
  PRESTAMOFORM_ISLOADING,
  PRESTAMOFORM_SUBMITFORM,
  PRESTAMOFORM_GETPLAZOS_INITED,
  PRESTAMOFORM_GETPLAZOS_COMPLETED,
  PRESTAMOFORM_GETPLAZOS_FAILED,
  PRESTAMOFORM_SELECTPLAZO
} from "./actions";

const initialState = {
  isLoading: false,
  importe: 5000,
  minPrestamo: 5000,
  plazo: null,
  plazos: []
};

const prestamoFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRESTAMOFORM_SELECTPLAZO:
      return {
        ...state,
        plazo: action.payload.plazo
      };
    case PRESTAMOFORM_INIT:
      return initialState;
    case PRESTAMOFORM_LOAD:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    case PRESTAMOFORM_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case PRESTAMOFORM_SUBMITFORM:
      return {
        ...state,
        importe: action.payload.Importe,
        plazo: action.payload.Plazo
      };
    case PRESTAMOFORM_GETPLAZOS_INITED:
      return {
        ...state,
        plazos: [],

        isLoading: true
      };
    case PRESTAMOFORM_GETPLAZOS_COMPLETED:
      let indexPlazo = action.payload.plazos.length > 1 ? 1 : 0;
      let plazo = state.plazo ? state.plazo : action.payload.plazos[indexPlazo];
      return {
        ...state,
        isLoading: false,
        plazo: plazo,
        plazos: action.payload.plazos
      };
    case PRESTAMOFORM_GETPLAZOS_FAILED:
      return {
        ...state,
        isLoading: false,
        plazos: [12, 18, 24, 36, 48],
        plazo: 18
      };
    default:
      return state;
  }
};

export default prestamoFormReducer;
