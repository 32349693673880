import amplitude from "amplitude-js";
import environment from "../environment";

// docs: https://developers.amplitude.com/

export const initAnalytics = appVersion => {
  if (environment.name === "localhost") return; // disable analytics on localhost

  amplitude.getInstance().init(environment.aplitudeAPIkey);
  amplitude.getInstance().setVersionName(appVersion);
};

export const logOutUserAnalytics = () => {
  if (environment.name === "localhost") return; // disable analytics on localhost

  amplitude.getInstance().setUserId(null);
  //amplitude.getInstance().regenerateDeviceId();
};

export const logInUserAnalytics = (email, name, userId, appVersion) => {
  if (environment.name === "localhost") return; // disable analytics on localhost

  amplitude.getInstance().setUserId(userId);
  amplitude.getInstance().setUserProperties({
    email,
    nombre: name,
    userId,
    appVersion
  });
};

export const logEventAnalytics = (eventName, eventData) => {
  if (environment.name === "localhost") return; // disable analytics on localhost

  amplitude.getInstance().logEvent(eventName, eventData);
};
