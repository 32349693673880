import {
  SEGUROS_COTIZAR_VEHICULO_MARCA_SELECT,
  SEGUROS_COTIZAR_VEHICULO_ANIO_SELECT,
  SEGUROS_COTIZAR_VEHICULO_MODELO_SELECT,
  SEGUROS_COTIZAR_PROVINCIA_SELECT,
  SEGUROS_COTIZAR_CODIGO_POSTAL_SELECT,
  SEGUROS_COTIZAR_VEHICULO_USO_SELECT,
  SEGUROS_COTIZAR_VEHICULO_GNC_SELECT,
  SEGUROS_COTIZAR_SEXO_SELECT,
  SEGUROS_COTIZAR_ESTADO_CIVIL_SELECT,
  SEGUROS_COTIZAR_FECHA_NAC_SELECT,
  SEGUROS_COTIZAR_FORMA_PAGO_SELECT
} from "./cotizadorSegurosFormActions";
import {
  SEGUROS_PEDIDO_COTIZACION_INITED,
  SEGUROS_PEDIDO_COTIZACION_COMPLETED,
  SEGUROS_PEDIDO_COTIZACION_FAILED,
  SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED,
  SEGUROS_COTIZACIONES_CLEAR,
  SEGUROS_COTIZACIONES_SEGURO_SELECT,
  SEGUROS_QUIERO_QUE_ME_CONTACTEN,
  SEGUROS_QUIERO_ESTA_POLIZA
} from "./cotizadorSegurosActions";

const initialState = {
  vehiculoMarcaSelected: null,
  vehiculoAnioSelected: null,
  vehiculoModeloSelected: null,
  vehiculoUsoSelected: null,
  vehiculoGNCSelected: null,
  provinciaSelected: null,
  codigoPostalSelected: null,
  sexoSelected: null,
  formaPagoSelected: null,
  isLoading: false,
  seguros: null,
  cotizacionesCounter: 0,
  cotizacionId: null,
  formValid: false,
  seguroSelected: null,
  finalizado: false
};

const validateFields = state => {
  const {
    vehiculoMarcaSelected,
    vehiculoAnioSelected,
    vehiculoModeloSelected,
    provinciaSelected,
    codigoPostalSelected,
    vehiculoUsoSelected,
    vehiculoGNCSelected,
    formaPagoSelected
  } = state;

  const allCompleted =
    vehiculoMarcaSelected &&
    vehiculoAnioSelected &&
    vehiculoModeloSelected &&
    provinciaSelected &&
    codigoPostalSelected &&
    vehiculoUsoSelected &&
    vehiculoGNCSelected &&
    formaPagoSelected;

  return allCompleted ? true : false;
};

const reducer = (state = initialState, action) => {
  let formValid = false;
  let resultState = state;

  switch (action.type) {
    case SEGUROS_COTIZAR_VEHICULO_MARCA_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        vehiculoModeloSelected: null,
        vehiculoMarcaSelected: action.payload.vehiculoMarcaSelected
          ? { ...action.payload.vehiculoMarcaSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_FORMA_PAGO_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        formaPagoSelected: action.payload.formaPagoSelected
          ? { ...action.payload.formaPagoSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_VEHICULO_ANIO_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        vehiculoModeloSelected: null,
        vehiculoAnioSelected: action.payload.vehiculoAnioSelected
          ? { ...action.payload.vehiculoAnioSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_VEHICULO_MODELO_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        vehiculoModeloSelected: action.payload.vehiculoModeloSelected
          ? { ...action.payload.vehiculoModeloSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_VEHICULO_USO_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        vehiculoUsoSelected: action.payload.vehiculoUsoSelected
          ? { ...action.payload.vehiculoUsoSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_VEHICULO_GNC_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        vehiculoGNCSelected: action.payload.vehiculoGNCSelected
          ? { ...action.payload.vehiculoGNCSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_PROVINCIA_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        codigoPostalSelected: null,
        provinciaSelected: action.payload.provinciaSelected
          ? { ...action.payload.provinciaSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_CODIGO_POSTAL_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        codigoPostalSelected: action.payload.codigoPostalSelected
          ? { ...action.payload.codigoPostalSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_SEXO_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        sexoSelected: action.payload.sexoSelected
          ? { ...action.payload.sexoSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_ESTADO_CIVIL_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        estadoCivilSelected: action.payload.estadoCivilSelected
          ? { ...action.payload.estadoCivilSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZAR_FECHA_NAC_SELECT:
      resultState = {
        ...state,
        cotizacionId: null,
        fechaNacimientoSelected: action.payload.fechaNacimientoSelected
          ? { ...action.payload.fechaNacimientoSelected }
          : null
      };
      formValid = validateFields(resultState);
      return { ...resultState, formValid };
    case SEGUROS_COTIZACIONES_CLEAR:
      return { ...initialState };
    case SEGUROS_PEDIDO_COTIZACION_INITED:
      if (state.formValid)
        return {
          ...state,
          cotizacionId: null,
          seguros: null,
          cotizacionesCounter: 0,
          seguroSelected: null,
          isLoading: true
        };
      else return state;
    case SEGUROS_PEDIDO_COTIZACION_FAILED:
      if (state.formValid)
        return {
          ...state,
          isLoading: false
        };
      else return state;
    case SEGUROS_PEDIDO_COTIZACION_COMPLETED:
      if (state.formValid)
        return {
          ...state,
          cotizacionId: action.payload.cotizacionId
        };
      else return state;
    case SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED:
      if (state.formValid)
        return {
          ...state,
          isLoading: false,
          cotizacionesCounter: state.cotizacionesCounter + 1,
          seguros: action.payload.seguros
        };
      else return state;
    case SEGUROS_COTIZACIONES_SEGURO_SELECT:
      if (state.formValid)
        return {
          ...state,
          seguroSelected: { ...action.payload.seguroSelected },
          finalizado: false
        };
      else return state;
    case SEGUROS_QUIERO_ESTA_POLIZA:
      if (state.formValid)
        return {
          ...state,
          finalizado: true
        };
      else return state;
    case SEGUROS_QUIERO_QUE_ME_CONTACTEN:
      if (state.formValid)
        return {
          ...state,
          finalizado: true
        };
      else return state;
    default:
      return state;
  }
};

export default reducer;
