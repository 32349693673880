import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FeatureFlagRender from "./FeatureFlagRender";

const mapStateToProps = (state, ownProps) => {
  // Having `displayName` is very useful for debugging.
  FeatureFlagRenderContainer.displayName = `FeatureFlagRenderContainer(${
    ownProps.name
  })`;

  return {
    user: state.session.user,
    value: state.featureFlagReducer.featureFlags[ownProps.name]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const FeatureFlagRenderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureFlagRender);

export default FeatureFlagRenderContainer;
