import React, { useMemo, memo } from "react";
import { Link } from "react-router-dom";
import { ListItem, List, makeSelectable } from "material-ui/List";
import Divider from "material-ui/Divider";
import localRepository from "../../localRepository";
import lang from "../../lang";
import { makeStyles, useTheme } from "../../libs/styles";

import useReactRouter from "use-react-router";
import { useSelector, useDispatch } from "react-redux";

import newRequest from "material-ui/svg-icons/av/playlist-add";
import myOperations from "material-ui/svg-icons/action/list";
import calculator from "material-ui/svg-icons/communication/dialpad";
import comercial from "material-ui/svg-icons/communication/contacts";
import directions_car from "material-ui/svg-icons/maps/directions-car";
import seguros from "material-ui/svg-icons/hardware/security";
import solvencia from "../../icons/SolvenciaIcon";
import inicioIcon from "material-ui/svg-icons/action/home";
import ExitIcon from "material-ui/svg-icons/action/exit-to-app";
import UserIcon from "material-ui/svg-icons/action/account-circle";
import VersionIcon from "material-ui/svg-icons/action/track-changes";
import {
  iniciarSolicitudButton,
  evaluarCompradorButton,
  asegurarAutoButton,
  cotizarAutoButton,
  simularPrestamoButton,
  misSolicitudesButton,
  homeButton,
  contactarComercialButton
} from "../home/actions";

const useStyles = makeStyles(theme => ({
  item: {
    lineHeight: [["22px"], "!important"],
    "@media (min-width: 994px)": {
      fontSize: [[14], "!important"]
    }
  }
}));

const SelectableNavList = makeSelectable(List);

const useReduxSelector = () =>
  useSelector(state => ({
    userFullName: state.session.user
      ? state.session.user.preferred_username
      : "",
    isDevModeEnabled: state.featureFlagReducer.featureFlags["dev-mode"]
  }));

const MainMenu = memo(() => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { location } = useReactRouter();
  const { userFullName, isDevModeEnabled } = useReduxSelector();
  const userName = userFullName.split(" ")[0];
  const indexOffset = 1;
  const selectedIndex = useMemo(() => {
    const allPathnames = menuList.map(x => x.Link);
    return allPathnames.findIndex(
      x =>
        x === location.pathname ||
        (x.length > 1 && location.pathname.startsWith(x))
    );
  }, [location.pathname]);

  return (
    <SelectableNavList
      style={{ zIndex: 999 }}
      value={selectedIndex + indexOffset}
    >
      <ListItem
        primaryText={`Hola, ${userName}`}
        leftIcon={<UserIcon color={theme.palette.textColor} />}
        disabled={true}
        style={{ marginBottom: 8, fontWeight: 400 }}
      />
      <Divider />
      {menuList.map((item, index) => (
        <ListItem
          key={item.id}
          value={index + indexOffset}
          containerElement={
            <Link
              to={{ pathname: item.Link, state: { new: true } }}
              id={"menu_" + item.id}
            />
          }
          primaryText={item.Nombre}
          leftIcon={
            <item.Icon
              color={theme.palette.primary3Color}
              style={{ height: "50%" }}
            />
          }
          hoverColor={theme.palette.primary4Color}
          className={classes.item}
          onClick={() => dispatch(item.action({ isSideBar: true }))}
        />
      ))}
      <Divider />
      <ListItem
        id={"menu_salir"}
        key={"logout-999"}
        value={999}
        primaryText={lang.mainMenu.exitLabel}
        leftIcon={<ExitIcon color={theme.palette.primary3Color} />}
        containerElement={<Link to={"/logout"} />}
        className={classes.item}
      />
      <Divider />
      <ListItem
        id={"menu_appversion"}
        primaryText={localRepository.version.get()}
        leftIcon={<VersionIcon color={theme.palette.textColor} />}
        containerElement={isDevModeEnabled ? <Link to={"/devinfo"} /> : null}
        disabled={isDevModeEnabled ? false : true}
        className={classes.item}
      />
    </SelectableNavList>
  );
});

export default MainMenu;

// private functions

const menuList = [
  {
    id: "0000",
    Nombre: "Inicio",
    Actividad: "001",
    Link: "/",
    Icon: inicioIcon,
    SubItems: [],
    action: homeButton
  },
  {
    id: "0001",
    Nombre: "Iniciar Solicitud",
    Actividad: "001",
    Link: "/creditos",
    Icon: newRequest,
    SubItems: [],
    action: iniciarSolicitudButton
  },
  {
    id: "0002",
    Nombre: "Mis Solicitudes",
    Actividad: "001",
    Link: "/solicitudes",
    Icon: myOperations,
    SubItems: [],
    action: misSolicitudesButton
  },
  {
    id: "0003",
    Nombre: "Simular Préstamo",
    Actividad: "001",
    Link: "/calculador",
    Icon: calculator,
    SubItems: [],
    action: simularPrestamoButton
  },
  {
    id: "0004",
    Nombre: "Cotizar Auto",
    Actividad: "001",
    Link: "/autos",
    Icon: directions_car,
    SubItems: [],
    action: cotizarAutoButton
  },
  {
    id: "0005",
    Nombre: "Asegurar Auto",
    Actividad: "001",
    Link: "/seguros",
    Icon: seguros,
    SubItems: [],
    action: asegurarAutoButton
  },
  {
    id: "0006",
    Nombre: "Evaluar Comprador",
    Actividad: "001",
    Link: "/creditos",
    Icon: solvencia,
    SubItems: [],
    action: evaluarCompradorButton
  },
  {
    id: "0007",
    Nombre: "Contactar Comercial",
    Actividad: "001",
    Link: "/contacto",
    Icon: comercial,
    SubItems: [],
    action: contactarComercialButton
  }
];
