const environment = {
  name: process.env.REACT_APP_ENV,

  myUrl: process.env.REACT_APP_SPA_URL,
  authUrl: process.env.REACT_APP_API_AUTH_URL,
  originacionUrl: process.env.REACT_APP_API_ORIGINACION_URL,
  catalogosUrl: process.env.REACT_APP_API_CATALOGOS_URL,
  creditRiskUrl: process.env.REACT_APP_API_CREDITRISK_URL,
  segurosUrl: process.env.REACT_APP_API_SEGUROS_URL,
  presignerUrl: process.env.REACT_APP_API_PRESIGNER_URL,

  isWIP: JSON.parse(process.env.REACT_APP_WIP),

  aplitudeAPIkey: process.env.REACT_APP_AMPLITUDE_API_KEY,

  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID
  },

  sentryUrl: process.env.REACT_APP_SENTRY_URL,

  launchDarkly: {
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDKKEY
  },

  terminosYCondicionesVersion:
    process.env.REACT_APP_TERMINOS_Y_CONDICIONES_VERSION,
  terminosYCondicionesFecha: process.env.REACT_APP_TERMINOS_Y_CONDICIONES_FECHA,
  appName: process.env.REACT_APP_APP_NAME,
  awsLogs: {
    apiKey: process.env.REACT_APP_AWS_LOGS_API_KEY,
    apiSecret: process.env.REACT_APP_AWS_LOGS_API_SECRET
  }
};

export default environment;
