import {
  LISTASOLICITUDES_ISLOADING,
  LISTASOLICITUDES_FETCHSUCCESS,
  LISTASOLICITUDES_CLEAR_LISTITEMS
} from "./actions";

const initialState = {
  isLoading: false,
  currentRowSize: 10,
  currentPage: 0,
  filterText: "",
  hasMore: true,
  solicitudes: {
    total: 0,
    items: [],
    skipped: 0,
    taken: 0
  },
  solicitudesItems: []
  // selectedItem: null
};

export default function listaSolicitudesReducer(state = initialState, action) {
  switch (action.type) {
    case LISTASOLICITUDES_ISLOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case LISTASOLICITUDES_CLEAR_LISTITEMS:
      return { ...state, currentPage: 0, filterText: "", solicitudesItems: [] };
    case LISTASOLICITUDES_FETCHSUCCESS:
      const currentItems = state.solicitudesItems.slice();
      let newItems = action.payload.solicitudes.items;
      const lastSolicitud = action.payload.lastSolicitud.get();

      if (state.solicitudesItems.length === 0 && lastSolicitud) {
        newItems = newItems.filter(
          item => item.id !== lastSolicitud.solicitudCreditoUI.solicitudId
        );
        newItems.unshift({
          id: lastSolicitud.solicitudCreditoUI.solicitudId,
          nombre: lastSolicitud.identidadForm.nombre,
          apellido: lastSolicitud.identidadForm.apellido,
          situacion: "En_Espera_De_Analisis",
          marca: lastSolicitud.autoForm.selectedMarca.value,
          modelo: lastSolicitud.autoForm.selectedModelo.label,
          anio: lastSolicitud.autoForm.selectedAnio.value,
          plazo: lastSolicitud.prestamoForm.plazo,
          importeNetoAPercibir: lastSolicitud.prestamoForm.importe,
          isNew: true
        });
      }
      const solicitudesItems = currentItems.concat(newItems);
      const hasMore = calcMore(
        action.payload.page,
        state.currentPage,
        action.payload.solicitudes.total
      );

      return {
        ...state,
        currentPage: action.payload.page,
        hasMore,
        filterText: action.payload.filterText,
        solicitudes: { ...action.payload.solicitudes },
        solicitudesItems
      };
    default:
      return state;
  }
}

const calcMore = (page, take, solicitudesItemsCount) => {
  const hasMore = solicitudesItemsCount >= page * take;

  return hasMore;
};
