import metadata from "./metadata";

let getLocalStorage = function() {
  return window && window.localStorage
    ? window.localStorage
    : { currentLang: "es" };
};

const localRepository = {
  clear: function() {
    const terminosYCondiciones = getLocalStorage().terminosYCondiciones;
    getLocalStorage().clear();
    if (typeof terminosYCondiciones !== "undefined")
      getLocalStorage().terminosYCondiciones = terminosYCondiciones;
  },

  auth: {
    get: function() {
      return getLocalStorage().auth
        ? JSON.parse(getLocalStorage().auth)
        : { token: null, user: { roles: "", preferred_username: "Error" } };
    },
    set: function(auth) {
      getLocalStorage().auth = JSON.stringify(auth);
    }
  },

  terminosYCondiciones: {
    get: function() {
      return getLocalStorage().terminosYCondiciones !== "undefined" &&
        getLocalStorage().terminosYCondiciones
        ? JSON.parse(getLocalStorage().terminosYCondiciones)
        : {
            app: null,
            version: null,
            idUsuario: null,
            name: null
          };
    },
    set: function(terminosYCondiciones) {
      if (typeof terminosYCondiciones !== "undefined") {
        getLocalStorage().terminosYCondiciones = JSON.stringify(
          terminosYCondiciones
        );
      }
    }
  },

  isOnNativeApp: {
    get: function() {
      return getLocalStorage().isOnNativeApp
        ? JSON.parse(getLocalStorage().isOnNativeApp)
        : false;
    },
    set: function(value) {
      getLocalStorage().isOnNativeApp = JSON.stringify(value);
    }
  },

  hasCurrentLang: {
    get: function() {
      return getLocalStorage().currentLang ? true : false;
    }
  },

  version: {
    get: function() {
      return `v${metadata.version.major}.${metadata.version.minor}.${
        metadata.version.build
      }.${metadata.version.revision}${
        process.env.REACT_APP_ENV !== "production"
          ? " (" + process.env.REACT_APP_ENV.substring(0, 3) + ")"
          : ""
      }`;
    }
  },

  currentLang: {
    get: function() {
      return getLocalStorage().currentLang
        ? getLocalStorage().currentLang
        : "es";
    },
    set: function(lang) {
      getLocalStorage().currentLang = lang;
    }
  },

  canales: {
    get: function() {
      return getLocalStorage().canales
        ? JSON.parse(getLocalStorage().canales)
        : [];
    },
    set: function(canales) {
      getLocalStorage().canales = JSON.stringify(canales);
    }
  },

  isAuthenticated: {
    get: function() {
      return getLocalStorage().isAuthenticated
        ? JSON.parse(getLocalStorage().isAuthenticated)
        : false;
    },
    set: function(isAuthenticated) {
      getLocalStorage().isAuthenticated = JSON.stringify(isAuthenticated);
    }
  },

  firebaseTokenSent: {
    get: function() {
      return getLocalStorage().firebaseTokenSent
        ? JSON.parse(getLocalStorage().firebaseTokenSent)
        : false;
    },
    set: function(firebaseTokenSent) {
      getLocalStorage().firebaseTokenSent = firebaseTokenSent;
    }
  },

  featureFlags: {
    get: function() {
      return getLocalStorage().featureFlags
        ? JSON.parse(getLocalStorage().featureFlags)
        : {};
    },
    set: function(featureFlags) {
      getLocalStorage().featureFlags = JSON.stringify(featureFlags);
    }
  },

  notificationPermission: {
    get: function() {
      return localStorage.getItem("notification-permission")
        ? JSON.parse(localStorage.getItem("notification-permission"))
        : false;
    },
    set: function(notificationPermission) {
      localStorage.setItem(
        "notification-permission",
        JSON.stringify(notificationPermission)
      );
    }
  },

  solicitud: {
    get: function() {
      return localStorage.getItem("solicitud")
        ? JSON.parse(localStorage.getItem("solicitud"))
        : false;
    },
    set: function(state) {
      localStorage.setItem("solicitud", JSON.stringify(state));
    },
    clear: () => {
      localStorage.removeItem("solicitud");
    }
  },

  lastSolicitud: {
    get: function() {
      return localStorage.getItem("lastSolicitud")
        ? JSON.parse(localStorage.getItem("lastSolicitud"))
        : false;
    },
    set: function(state) {
      localStorage.setItem("lastSolicitud", JSON.stringify(state));
    },
    clear: () => {
      localStorage.removeItem("lastSolicitud");
    }
  },

  agencia: {
    get: function() {
      return localStorage.getItem("agencia")
        ? JSON.parse(localStorage.getItem("agencia"))
        : false;
    },
    set: function(state) {
      localStorage.setItem("agencia", JSON.stringify(state));
    },
    clear: () => {
      localStorage.removeItem("agencia");
    }
  },

  launchDarkly: {
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDKKEY
  },

  mostUsedProv: {
    get: function() {
      return localStorage.getItem("mostUsedProv")
        ? JSON.parse(localStorage.getItem("mostUsedProv"))
        : [];
    },
    set: function(state) {
      const mostUs = this.get();
      const prov = mostUs.filter(prov => prov.label === state.label);
      if (prov.length > 0) prov[0].count++;
      else mostUs.push({ ...state, count: 0 });
      localStorage.setItem("mostUsedProv", JSON.stringify(mostUs));
    },
    clear: () => {
      localStorage.removeItem("mostUsedProv");
    }
  },
  mostUsedLoc: {
    get: function() {
      return localStorage.getItem("mostUsedLoc")
        ? JSON.parse(localStorage.getItem("mostUsedLoc"))
        : [];
    },
    set: function(state) {
      const mostUs = this.get();
      const loc = mostUs.filter(loc => loc.label === state.label);
      if (loc.length > 0) loc[0].count++;
      else mostUs.push({ ...state, count: 0 });
      localStorage.setItem("mostUsedLoc", JSON.stringify(mostUs));
    },
    clear: () => {
      localStorage.removeItem("mostUsedLoc");
    }
  },
  mostUsedCod: {
    get: function() {
      return localStorage.getItem("mostUsedCod")
        ? JSON.parse(localStorage.getItem("mostUsedCod"))
        : [];
    },
    set: function(state) {
      const mostUs = this.get();
      const loc = mostUs.filter(loc => loc.label === state.label);
      if (loc.length > 0) loc[0].count++;
      else mostUs.push({ ...state, count: 0 });
      localStorage.setItem("mostUsedCod", JSON.stringify(mostUs));
    },
    clear: () => {
      localStorage.removeItem("mostUsedCod");
    }
  },
  mostUsedCodLoc: {
    get: function() {
      return localStorage.getItem("mostUsedCodLoc")
        ? JSON.parse(localStorage.getItem("mostUsedCodLoc"))
        : [];
    },
    set: function(state) {
      const mostUs = this.get();
      const loc = mostUs.filter(
        loc => loc.label === state.label && loc.loc === state.loc
      );
      if (loc.length > 0) loc[0].count++;
      else mostUs.push({ ...state, count: 0 });
      localStorage.setItem("mostUsedCodLoc", JSON.stringify(mostUs));
    },
    clear: () => {
      localStorage.removeItem("mostUsedCodLoc");
    }
  }
};

export default localRepository;
