export const SOLICITUD_SEGUROS_PEDIDO_COTIZACION_INITED =
  "SOLICITUD_SEGUROS_PEDIDO_COTIZACION_INITED";
export const SOLICITUD_SEGUROS_PEDIDO_COTIZACION_COMPLETED =
  "SOLICITUD_SEGUROS_PEDIDO_COTIZACION_COMPLETED";
export const SOLICITUD_SEGUROS_PEDIDO_COTIZACION_FAILED =
  "SOLICITUD_SEGUROS_PEDIDO_COTIZACION_FAILED";

export const SOLICITUD_SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED =
  "SOLICITUD_SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED";

export const SOLICITUD_SEGUROS_COTIZACIONES_CLEAR =
  "SOLICITUD_SEGUROS_COTIZACIONES_CLEAR";
export const SOLICITUD_SEGUROS_COTIZACIONES_SEGURO_SELECT =
  "SOLICITUD_SEGUROS_COTIZACIONES_SEGURO_SELECT";

export const SEGUROFORM_INIT = "SEGUROFORM_INIT";
export const SEGUROFORM_LOAD = "SEGUROFORM_LOAD";

export const SOLICITUD_SEGUROS_SUBMIT_FORM_INITED =
  "SOLICITUD_SEGUROS_SUBMIT_FORM_INITED";
export const SOLICITUD_SEGUROS_SUBMIT_FORM_COMPLETED =
  "SOLICITUD_SEGUROS_SUBMIT_FORM_COMPLETED";
export const SOLICITUD_SEGUROS_SUBMIT_FORM_FAILED =
  "SOLICITUD_SEGUROS_SUBMIT_FORM_FAILED";

export const fetchPedidoCotizacionSeguros = (solicitudId, successCallback) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchPedidoCotizacionInited());

      const responsePedidoCotizacion = await services.api.getSolicitudSegurosInicio(
        solicitudId
      );

      dispatch(fetchPedidoCotizacionCompleted(responsePedidoCotizacion));

      if (responsePedidoCotizacion && responsePedidoCotizacion.cotizacionId)
        dispatch(
          fetchCotizacionesCompaniasSeguros(
            solicitudId,
            responsePedidoCotizacion.cotizacionId,
            successCallback
          )
        );
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchPedidoCotizacionSeguros,
        [solicitudId, successCallback],
        getState()
      );
      dispatch(fetchPedidoCotizacionFailed(error));
      //EAG: this is being called in background in ofertas, we shouldn't bother the user with seguros errors
      //dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

export const fetchCotizacionesCompaniasSeguros = (
  solicitudId,
  cotizacionId,
  successCallback
) => {
  return async (dispatch, getState, services) => {
    try {
      const cotizacionesResult = await services.api.getSolicitudSegurosResult(
        solicitudId,
        cotizacionId
      );

      dispatch(fetchCotizacionesCompaniasSegurosCompleted(cotizacionesResult));

      if (successCallback) successCallback();
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchCotizacionesCompaniasSeguros,
        [solicitudId, cotizacionId, successCallback],
        getState()
      );

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

const fetchCotizacionesCompaniasSegurosCompleted = response => {
  let seguros = { cotizacionId: null, companias: [] };

  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0 &&
    response.companias &&
    response.companias.length > 0
  ) {
    seguros = response;
  }

  return {
    type: SOLICITUD_SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED,
    payload: {
      seguros
    }
  };
};

const fetchPedidoCotizacionInited = () => {
  return {
    type: SOLICITUD_SEGUROS_PEDIDO_COTIZACION_INITED
  };
};

const fetchPedidoCotizacionCompleted = response => {
  let cotizacionId = null;

  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0 &&
    response.cotizacionId
  ) {
    cotizacionId = response.cotizacionId;
  }

  return {
    type: SOLICITUD_SEGUROS_PEDIDO_COTIZACION_COMPLETED,
    payload: {
      cotizacionId
    },
    analytics: {
      eventName: "solicitud-seguros-pedido-cotizacion",
      eventData: {}
    }
  };
};

const fetchPedidoCotizacionFailed = error => {
  return {
    type: SOLICITUD_SEGUROS_PEDIDO_COTIZACION_FAILED,
    payload: {
      error
    }
  };
};

export const clearCotizacionesSeguros = () => {
  return {
    type: SOLICITUD_SEGUROS_COTIZACIONES_CLEAR,
    analytics: {
      eventName: "solicitud-seguros-nuevo-pedido",
      eventData: {}
    }
  };
};

export const seguroSelect = seguroSelected => {
  return {
    type: SOLICITUD_SEGUROS_COTIZACIONES_SEGURO_SELECT,
    payload: {
      seguroSelected
    },
    analytics: {
      eventName: "solicitud-seguros-cotizado-seleccionado",
      eventData: { ...seguroSelected }
    }
  };
};

export const seguroFormInit = () => ({
  type: SEGUROFORM_INIT
});

export const seguroFormLoad = data => ({
  type: SEGUROFORM_LOAD,
  payload: {
    data: data
  }
});

export const submitSeguroForm = (
  solicitudId,
  cotizacionId,
  seguroSelected,
  isPending,
  successCallback
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(submitSeguroFormInited());

      const requestSeguro = !isPending
        ? {
            Id: solicitudId,
            CotizacionId: cotizacionId,
            CotizacionPlanId: seguroSelected.cotizacionPlanId,
            TipoPolizaId: seguroSelected.tipoPolizaId,
            TipoCobertura: seguroSelected.tipoCobertura
          }
        : {
            Id: solicitudId,
            Pendiente: true
          };

      await services.api.postSolicitudSeguroCotizacion(requestSeguro);

      dispatch(submitSeguroFormCompleted(requestSeguro));

      if (successCallback) successCallback();
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        submitSeguroForm,
        [solicitudId, cotizacionId, seguroSelected, isPending, successCallback],
        getState()
      );
      dispatch(submitSeguroFormFailed(error));

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

const submitSeguroFormInited = () => {
  return {
    type: SOLICITUD_SEGUROS_SUBMIT_FORM_INITED
  };
};

const submitSeguroFormCompleted = seguroSelected => {
  return {
    type: SOLICITUD_SEGUROS_SUBMIT_FORM_COMPLETED,
    analytics: {
      eventName: "solicitud-seguros-datos-enviado",
      eventData: {
        ...seguroSelected
      }
    }
  };
};

const submitSeguroFormFailed = error => {
  return {
    type: SOLICITUD_SEGUROS_SUBMIT_FORM_FAILED,
    payload: {
      error
    }
  };
};
