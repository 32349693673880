import { logEventAnalytics } from "../libs/amplitudeHelper";

const analyticsMiddleware = store => next => action => {
  if (action.analytics) {
    try {
      console.debug("Analytics track", action.analytics);

      logEventAnalytics(action.analytics.eventName, {
        ...action.analytics.eventData,
        deviceInfo: { ...store.getState().nativeMessagesReducer.deviceInfo }
      });
    } catch (error) {
      console.error("Analytics error", error);
    }
  }

  if (action.meta && action.meta.analytics) {
    try {
      console.debug("Analytics track", action.meta.analytics);

      logEventAnalytics(action.meta.analytics.eventName, {
        ...action.meta.analytics.eventData,
        deviceInfo: { ...store.getState().nativeMessagesReducer.deviceInfo }
      });
    } catch (error) {
      console.error("Analytics error", error);
    }
  }

  return next(action);
};

export default analyticsMiddleware;
