import {
  getMostrarTerminos,
  checkTerminosYCondicionesCompleted
} from "../../terminosYCondiciones/actions";
import { canalFormClear } from "../../canalForm/actions";

export const LOG_IN_ISLOADING = "LOG_IN_ISLOADING";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_ERROR = "LOG_IN_ERROR";
export const LOG_OUT = "LOG_OUT";
export const LOG_IN_ISAUTHENTICATED = "LOG_IN_ISAUTHENTICATED";
export const RECOVERY_PASSWORD_SUCCESS = "RECOVERY_PASSWORD_SUCCESS";
export const WEB_AUTH_STATUS_CHANGE = "WEB_AUTH_STATUS_CHANGE";
export const WEB_LOCATION_CHANGE = "WEB_LOCATION_CHANGE";
export const RECOVER_PASSWORD_BEGIN = "RECOVER_PASSWORD_BEGIN";
export const RECOVER_PASSWORD_SENDED_SUCCESSFULLY =
  "RECOVER_PASSWORD_SENDED_SUCCESSFULLY";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";

export const logisloading = () => ({
  type: LOG_IN_ISLOADING,
  payload: {
    isLoading: true,
    logIn: false,
    error: null
  }
});

export const isAuthenticated = is => ({
  type: LOG_IN_ISAUTHENTICATED,
  payload: {
    logIn: is
  }
});
export const loginSuccess = user => ({
  type: LOG_IN_SUCCESS,
  payload: {
    isLoading: false,
    logIn: true,
    error: null,
    user: user
  }
});

export const loginError = error => ({
  type: LOG_IN_ERROR,
  payload: {
    isLoading: false,
    logIn: false,
    error: error
  }
});

export const logOut = () => ({
  type: LOG_OUT,
  payload: {
    isLoading: false,
    logIn: false,
    error: null
  }
});

export function logOutUser() {
  return async (dispatch, getState, services) => {
    services.localStorage.clear();
    dispatch(logOut());
  };
}

export function logInUser(credentials) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(logisloading());
      const response = await services.api.sessionLogin(credentials);
      let token = response.bearerToken;
      let user = services.jwt_decode(token);
      services.localStorage.auth.set({ token, user });
      services.localStorage.isAuthenticated.set(true);
      if (
        getState().featureFlagReducer.featureFlags
          ? getState().featureFlagReducer.featureFlags["webapp-terms"]
          : false
      ) {
        // TODO: remove this condition when the feature flag webapp-terms no more needed
        let mostrarTerminos = await getMostrarTerminos(user, services);
        dispatch(canalFormClear());
        dispatch(checkTerminosYCondicionesCompleted({ mostrarTerminos }));
      }
      dispatch(loginSuccess(user));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        logInUser,
        [credentials],
        getState()
      );

      dispatch(services.errorHandler.checkErrorAction(error));
      services.localStorage.clear();
      dispatch(loginError(error));
    }
  };
}

//#Recover Password

export function sendRecoveryPasswordEmail(email) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(logisloading());
      await services.api.sessionSendRecoveryPasswordEmail(email);
      dispatch(sendRecoveryPasswordEmailSuccess());
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        sendRecoveryPasswordEmail,
        [email],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(loginError(error));
    }
  };
}

export const sendRecoveryPasswordEmailSuccess = () => ({
  type: RECOVER_PASSWORD_SENDED_SUCCESSFULLY,
  payload: {
    isLoading: false,
    error: null
  }
});

export function recoverPassword(formData) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(recoverPasswordBegin());
      await services.api.postRecoverPassword(formData);

      dispatch(recoverPasswordSuccess());
      services.sendMessage("Contraseña confirmada correctamente.", "success");
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        recoverPassword,
        [formData],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(recoverPasswordError(error));
    }
  };
}

export const recoverPasswordBegin = () => ({
  type: RECOVER_PASSWORD_BEGIN,
  payload: {
    isLoading: true
  }
});

export const recoverPasswordSuccess = () => ({
  type: RECOVERY_PASSWORD_SUCCESS,
  payload: {
    isLoading: false,
    error: null
  }
});

export const recoverPasswordError = error => ({
  type: RECOVER_PASSWORD_ERROR,
  payload: {
    isLoading: false,
    error: error
  }
});
//#End Recover Password
