import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isAuthenticated } from "../user/js/actions";
import { checkTerminosYCondiciones } from "../terminosYCondiciones/actions";
import App from "./App";

const mapStateToProps = state => {
  return {
    needRefresh: state.app.needRefresh,
    isLoggedIn: state.session.logIn,
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    mostrarTerminos: state.terminosYCondicionesReducer.mostrarTerminos,
    featureFlagTerminosYCondiciones: state.featureFlagReducer.featureFlags
      ? state.featureFlagReducer.featureFlags["webapp-terms"]
      : false,
    user: state.session.user,
    terminosIsLoading: state.terminosYCondicionesReducer.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendIsAuthenticated: isAuthenticated,
      checkTerminosYCondiciones
    },
    dispatch
  );
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;
