import { combineReducers } from "redux";

import topBar from "../componentes/topBar/js/reducer";
import canalForm from "../componentes/canalForm/actions";
import solicitudCreditoReducer from "../componentes/solicitudCredito/js/reducer";
import identidadForm from "../componentes/identidadForm/actions";
import actividadForm from "../componentes/actividadForm/js/reducer";
import contactoForm from "../componentes/contactoForm/js/reducer";
import direccionForm from "../componentes/direccionForm/js/reducer";
import autoForm from "../componentes/autoForm/actions";
import prestamoForm from "../componentes/prestamoForm/js/reducer";
import ofertasForm from "../componentes/ofertasForm/js/reducer";
import seguroFormReducer from "../componentes/seguroForm/js/reducer";
import resultadoForm from "../componentes/resultadoForm/js/reducer";
import listaSolicitudes from "../componentes/listaSolicitudes/js/reducer";
import detalleSolicitud from "../componentes/detalleSolicitud/js/reducer";
import calculador from "../componentes/calculador/js/reducer";
import nativeMessagesReducer from "../componentes/nativeAppMessaging/js/reducer";
import cotizadorAutoReducer from "../componentes/cotizadorAuto/js/actions";
import contactoComercial from "../componentes/contactoComercial/js/reducer";
import vehiculosMarcasReducer from "../componentes/shared/vehiculoMarcaSelector/js/reducer";
import cotizadorSegurosReducer from "../componentes/cotizadorSeguros/js/reducer";
import vehiculosModelosReducer from "../componentes/shared/vehiculoModeloSelector/js/reducer";
import codigosPostalesReducer from "../componentes/shared/codigoPostalSelector/js/reducer";
import localidadReducer from "../componentes/shared/localidadSelector/js/reducer";
import featureFlagReducer from "../componentes/shared/featureFlags/js/reducer";
import sessionReducer from "../componentes/user/js/reducer";
import pedidoCambioReducer from "../componentes/pedidoCambio/actions";
import solicitudTabs from "../componentes/solicitudTabs/js/reducer";
import listaTareas from "../componentes/listaTareas/js/reducer";
import agregarConyugeReducer from "../componentes/agregarConyuge/js/actions";
import agregarPersonaReducer from "../componentes/agregarPersona/js/actions";
import signupAgenciaReducer from "../componentes/signupAgencia/js/reducer";
import identidadReducer from "../componentes/identidadPersona/actions";
import app from "./appReducer";
import agregarCodeudorReducer from "../componentes/agregarCodeudor/actions";
import direccionPersonaReducer from "../componentes/direccionPersona/actions";
import contactoPersonaReducer from "../componentes/contactoPersona/actions";
import actividadPersonaReducer from "../componentes/actividadPersona/actions";
import terminosYCondicionesReducer from "../componentes/terminosYCondiciones/actions";
import capturarAutoReducer from "../componentes/capturarAuto/actions";
import contactoSegurosReducer from "../componentes/cotizadorSeguros/contactoSeguros/actions";
import tareasSolicitudReducer from "../componentes/tareasSolicitud/js/actions";
import TareaConyugeReducer from "../componentes/tareasSolicitud/tareaConyuge/actions";
import AgregarCodeudorTareaReducer from "../componentes/tareasSolicitud/tareaCodeudor/agregarCodeudor/actions";
import AgregarConyugeCodeudorTareaReducer from "../componentes/tareasSolicitud/tareaCodeudor/agregarConyugeCodeudor/actions";
import TareaCodeudorReducer from "../componentes/tareasSolicitud/tareaCodeudor/actions";
import TareaTelefonoReducer from "../componentes/tareasSolicitud/tareaTelefono/actions";
const creditosAppReducer = combineReducers({
  session: sessionReducer,
  featureFlagReducer,
  topBar,
  solicitudCreditoUI: solicitudCreditoReducer,
  canalForm,
  identidadForm,
  actividadForm,
  contactoForm,
  direccionForm,
  autoForm,
  prestamoForm,
  ofertasForm,
  seguroForm: seguroFormReducer,
  resultadoForm,
  listaSolicitudes,
  detalleSolicitud,
  calculador,
  contactoComercial,
  app,
  listaTareas,
  solicitudTabs,
  signupAgencia: signupAgenciaReducer,
  nativeMessagesReducer,
  pedidoCambioReducer,
  cotizadorAutoReducer,
  vehiculosMarcasReducer,
  cotizadorSegurosReducer,
  vehiculosModelosReducer,
  codigosPostalesReducer,
  localidadReducer,
  agregarConyugeReducer,
  agregarPersonaReducer,
  identidadReducer,
  agregarCodeudorReducer,
  direccionPersonaReducer,
  contactoPersonaReducer,
  actividadPersonaReducer,
  terminosYCondicionesReducer,
  capturarAutoReducer,
  contactoSegurosReducer,
  tareasSolicitudReducer,
  TareaConyugeReducer,
  AgregarCodeudorTareaReducer,
  AgregarConyugeCodeudorTareaReducer,
  TareaCodeudorReducer,
  TareaTelefonoReducer
});

export default creditosAppReducer;
