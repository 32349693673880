import React from "react";
import ReactDOM from "react-dom";
import AppContainer from "./componentes/app/AppContainer";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import localRepository from "./localRepository";
import environment from "./environment";
import takeOverConsole from "./libs/takeOverConsole";

import { initAnalytics, logInUserAnalytics } from "./libs/amplitudeHelper";

import { FeatureFlagListenerContainer } from "./componentes/shared/featureFlags";

import "./css/cleanup.css";

takeOverConsole();

const store = configureStore({
  session: {
    logIn: localRepository.isAuthenticated.get(),
    error: null,
    isLoading: false,
    user: localRepository.isAuthenticated.get()
      ? localRepository.auth.get().user
      : null
  },
  app: {
    needRefresh: false,
    firebaseTokenSent: false
  }
}); // Optional pass in an initialState.

initAnalytics(localRepository.version.get());

if (localRepository.isAuthenticated.get())
  logInUserAnalytics(
    localRepository.auth.get().user.email,
    localRepository.auth.get().user.preferred_username,
    localRepository.auth.get().user.sub,
    localRepository.version.get()
  );

console.info("RUN LANG: " + localRepository.currentLang.get());
console.info("RUN MODE: " + environment.name);

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <FeatureFlagListenerContainer />
      <AppContainer />
    </React.Fragment>
  </Provider>,
  document.getElementById("root")
);
