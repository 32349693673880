import { createMuiTheme } from "@material-ui/core/styles";

const purple1 = "hsl(263, 46%, 45%)";
const purple2 = "hsl(260, 44%, 45%)";
const purple3 = "hsl(263, 45%, 35%)";
const purple4 = "hsl(231, 44%, 94%)";

const cyan1 = "hsl(190, 63%, 57%)";
const cyan2 = "hsl(190, 52%, 65%)";
const cyan3 = "hsl(190, 63%, 85%)";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: purple1,
      main: purple2,
      dark: purple3,
      contrastText: purple4
    },
    secondary: {
      light: cyan3,
      main: cyan2,
      dark: cyan1
    }
  }
});

export default theme;
