import {
  VEHICULOS_MARCAS_COMPLETED,
  VEHICULOS_MARCAS_INITED,
  VEHICULOS_MARCAS_FAILED
} from "./actions";

const initialState = {
  isLoading: false,
  marcas: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VEHICULOS_MARCAS_INITED:
      return {
        ...state,
        isLoading: true
      };
    case VEHICULOS_MARCAS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case VEHICULOS_MARCAS_COMPLETED:
      return {
        ...state,
        marcas: [...action.payload.marcas],
        isLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
