import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import analyticsMiddleware from "./middlewares/analyticsMiddleware";
import analyticsAuthMiddleware from "./middlewares/analyticsAuthMiddleware";
import * as api from "./api";
import localRepository from "./localRepository";
import jwt_decode from "jwt-decode";
import {
  onAuthStatusChangeMiddleware,
  onTopBarSetMiddleware,
  onToggleMainMenuMiddleware,
  onShowNativePopupMiddleware
} from "./componentes/nativeAppMessaging/js/middleware";

import { createLogRemoteError } from "./libs/createLogRemoteError";

import {
  checkErrorAction,
  getErrorMessage,
  getErrorCode,
  isTokenExpiredError
} from "./libs/checkError";

import { sendMessage, sendError } from "./libs/sendMessage";

import { logOutUser } from "./componentes/user/js/actions";
import fileService from "~libs/fileService";

const defaultServices = {
  api,
  fileService,
  errorHandler: {
    checkErrorAction: checkErrorAction,
    getErrorMessage,
    sendError,
    isTokenExpiredError,
    logOutUser,
    logRemoteError: createLogRemoteError(),
    getErrorCode
  },
  sendMessage,
  localStorage: localRepository,
  jwt_decode
};

export default function configureStore(
  initialState,
  services = defaultServices
) {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(
        thunk.withExtraArgument(services),
        analyticsMiddleware,
        analyticsAuthMiddleware,
        onAuthStatusChangeMiddleware,
        onTopBarSetMiddleware,
        onToggleMainMenuMiddleware,
        onShowNativePopupMiddleware
      )
    )
  );
}
