import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import MessageViewer from "../notifications/MessageViewer";
import MainMenu from "../menu/MainMenu";
import RedirectToHttps from "../shared/redirectToHttps";
import localRepository from "../../localRepository";

import TopBarContainer from "../topBar/TopBarContainer";
import NativeAppMessagingContainer from "../nativeAppMessaging";
import LogoutContainer from "../user/LogoutContainer";

import muiMGTheme from "~css/muiMGTheme";
import { FeatureFlagRenderContainer } from "../shared/featureFlags";

import { makeStyles } from "~libs/styles";

// new material
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import mgTheme from "~css/mgTheme";

import {
  AsyncLogin,
  AsyncRecoverPassword,
  AsyncPasswordForm,
  AsyncSignupAgencia,
  AsyncSolicitudTabs,
  AsyncHome,
  AsyncTest,
  AsyncContactoComercial,
  AsyncCotizadorAuto,
  AsyncCalculador,
  AsyncSolicitudCredito,
  AsyncListaSolicitudes,
  AsyncCotizadorSeguros,
  AsyncAgregarPersona,
  AsyncCapturarAuto,
  AsyncTerminosYCondiciones,
  AsyncAgregarCodeudor,
  AsyncAgregarConyugeCodeudor,
  AsyncDevInfo
} from "./LazyComponents";

const PublicViews = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/login" component={AsyncLogin} />
        <Route
          exact
          path="/login/password/:token"
          component={AsyncRecoverPassword}
        />
        <Route exact path="/agencias/signup" component={AsyncSignupAgencia} />
        <Route
          exact
          path="/agencias/signup/:token"
          component={AsyncPasswordForm}
        />
        <Redirect to="/login" /> {/*fallback to login*/}
      </Switch>
    </React.Fragment>
  );
};

const useAuthenticatedStyles = makeStyles(theme => ({
  containerFluid: {
    marginRight: "auto",
    marginLeft: "auto",
    paddingLeft: 0,
    paddingRight: 0,
    "&:before": {
      content: '" "',
      display: "table"
    },
    "&:after": {
      content: '" "',
      display: "table"
    }
  },
  row: {
    marginLeft: 0,
    marginRight: 0,
    "&:before": {
      content: '" "',
      display: "table"
    },
    "&:after": {
      clear: "both"
    }
  },
  mainContent: {
    position: "relative",
    minHeight: 1,
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    width: "100%",
    "@media (min-width: 994px)": {
      marginLeft: "16.6%",
      width: "calc(100% - 16.6%)"
    }
  },
  sideBar: {
    background: "#fff !important",
    borderRight: "solid #eee",
    position: "fixed",
    minHeight: 1,
    height: "100%",
    zIndex: 98,
    paddingLeft: 0,
    paddingRight: 0,
    width: "16.66666667%",
    "@media (max-width: 994px)": {
      display: "none !important"
    }
  }
}));

const AuthenticatedViews = ({
  location,
  isOnNativeApp,
  needRefresh,
  mostrarTerminos,
  terminosIsLoading
}) => {
  const classes = useAuthenticatedStyles();

  return (
    <div className={classes.containerFluid}>
      <div
        className={classes.row}
        style={{
          height: 64,
          display: "block"
        }}
      >
        <TopBarContainer needRefresh={needRefresh} />
      </div>
      <div className={classes.row}>
        <div className={classes.sideBar}>
          <MainMenu />
        </div>
        <FeatureFlagRenderContainer name={"webapp-terms"} when={false}>
          <div className={classes.mainContent}>
            <Switch location={location}>
              <Route
                exact
                path="/"
                component={AsyncHome}
                data-transition-id="home"
              />
              <Route path="/test" component={AsyncTest} />
              <Route path="/creditos" component={AsyncSolicitudCredito} />
              <Route path="/seguros" component={AsyncCotizadorSeguros} />
              <Route
                exact
                path="/solicitudes"
                component={AsyncListaSolicitudes}
              />
              <Route
                exact
                path="/solicitudes/:idSolicitud/agregarPersona"
                component={AsyncAgregarPersona}
              />
              <Route
                path="/solicitudes/:idSolicitud"
                component={AsyncSolicitudTabs}
              />
              <Route exact path="/logout" component={LogoutContainer} />
              <Route exact path="/calculador" component={AsyncCalculador} />
              <Route exact path="/autos" component={AsyncCotizadorAuto} />
              <Route
                exact
                path="/evaluador"
                component={AsyncSolicitudCredito}
              />
              <Route exact path="/capturarauto" component={AsyncCapturarAuto} />
              <Route
                exact
                path="/contacto"
                component={AsyncContactoComercial}
              />
              <Route
                exact
                path="/login/password/:token"
                component={AsyncRecoverPassword}
              />
              <Route
                exact
                path="/terminos"
                component={AsyncTerminosYCondiciones}
              />
              <Redirect to="/" />
            </Switch>
          </div>
        </FeatureFlagRenderContainer>

        <FeatureFlagRenderContainer name={"webapp-terms"} when={true}>
          <div className={classes.mainContent}>
            {mostrarTerminos === true && terminosIsLoading !== true && (
              <Switch location={location}>
                <Route
                  exact
                  path="/"
                  component={AsyncTerminosYCondiciones}
                  data-transition-id="home"
                />
                <Route exact path="/logout" component={LogoutContainer} />
                <Redirect to="/" />
              </Switch>
            )}
            {!mostrarTerminos && terminosIsLoading !== true && (
              <Switch location={location}>
                <Route
                  exact
                  path="/"
                  component={AsyncHome}
                  data-transition-id="home"
                />
                <Route path="/test" component={AsyncTest} />
                <Route path="/creditos" component={AsyncSolicitudCredito} />
                <Route path="/seguros" component={AsyncCotizadorSeguros} />
                <Route
                  exact
                  path="/solicitudes"
                  component={AsyncListaSolicitudes}
                />
                <Route
                  exact
                  path="/solicitudes/:idSolicitud/agregarPersona"
                  component={AsyncAgregarPersona}
                />
                <Route
                  exact
                  path="/solicitudes/:idSolicitud/agregarCodeudor"
                  component={AsyncAgregarCodeudor}
                />
                <Route
                  exact
                  path="/solicitudes/:idSolicitud/agregarConyugeCodeudor"
                  component={AsyncAgregarConyugeCodeudor}
                />
                <Route
                  path="/solicitudes/:idSolicitud"
                  component={AsyncSolicitudTabs}
                />
                <Route exact path="/logout" component={LogoutContainer} />
                <Route exact path="/calculador" component={AsyncCalculador} />
                <Route exact path="/autos" component={AsyncCotizadorAuto} />
                <Route
                  exact
                  path="/evaluador"
                  component={AsyncSolicitudCredito}
                />
                <Route
                  exact
                  path="/capturarauto"
                  component={AsyncCapturarAuto}
                />
                <Route
                  exact
                  path="/contacto"
                  component={AsyncContactoComercial}
                />
                <Route
                  exact
                  path="/login/password/:token"
                  component={AsyncRecoverPassword}
                />
                <Route exact path="/devinfo" component={AsyncDevInfo} />
                <Redirect to="/" />
              </Switch>
            )}
          </div>
        </FeatureFlagRenderContainer>
      </div>
    </div>
  );
};

const useAppStyles = makeStyles(theme => ({
  nonScrollableBodyDefault: {
    backgroundColor: theme.palette.canvasColor,
    position: "fixed",
    overflow: "hidden",
    width: "100%"
  }
}));

const App = ({
  isOnNativeApp,
  isLoggedIn,
  needRefresh,
  sendIsAuthenticated,
  mostrarTerminos,
  checkTerminosYCondiciones,
  featureFlagTerminosYCondiciones,
  user,
  terminosIsLoading
}) => {
  const classes = useAppStyles();
  useEffect(() => {
    if (featureFlagTerminosYCondiciones && isLoggedIn) {
      // TODO: remove this condition when the feature flag webapp-terms no more needed
      checkTerminosYCondiciones(user);
    }
  }, [
    user,
    checkTerminosYCondiciones,
    featureFlagTerminosYCondiciones,
    isLoggedIn
  ]);

  useEffect(() => {
    if (localRepository.isAuthenticated.get()) {
      sendIsAuthenticated(true); // TODO: is this really needed ???
    }
  }, [sendIsAuthenticated]);

  useEffect(() => {
    document.body.className = classes.nonScrollableBodyDefault;
  }, [classes.nonScrollableBodyDefault]);

  const supportsHistory = "pushState" in window.history;

  return (
    <BrowserRouter keyLength={12} forceRefresh={!supportsHistory}>
      <Route
        render={({ location }) => {
          return (
            <React.Fragment>
              <NativeAppMessagingContainer />
              <CssBaseline />
              <ThemeProvider theme={mgTheme}>
                <MuiThemeProvider muiTheme={muiMGTheme}>
                  <RedirectToHttps>
                    <React.Fragment>
                      <MessageViewer />
                      {isLoggedIn && (
                        <AuthenticatedViews
                          location={location}
                          isOnNativeApp={isOnNativeApp}
                          needRefresh={needRefresh}
                          mostrarTerminos={mostrarTerminos}
                          terminosIsLoading={terminosIsLoading}
                        />
                      )}
                      {!isLoggedIn && <PublicViews />}
                    </React.Fragment>
                  </RedirectToHttps>
                </MuiThemeProvider>
              </ThemeProvider>
            </React.Fragment>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default App;
