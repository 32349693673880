import React, {Component} from 'react';
import PropTypes from "prop-types";

class Logout extends Component {

  componentWillMount() {
    this.props.logOutUser();
  }

  render() {
    return (
      <div/>
    );
  }
}

Logout.propTypes = {
  logOutUser: PropTypes.func.isRequired
};

export default Logout;