import { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

const platform = require("platform");
const isiOS = platform.os.family === "iOS";

class NativeAppMessaging extends Component {
  componentWillMount() {
    const { history } = this.props;

    window.navigateTo = url => history.push(url);

    let messageHandler = event => {
      let eventDataObj = null;
      try {
        eventDataObj = JSON.parse(event.data);

        if (eventDataObj.type && eventDataObj.type.startsWith("RN_")) {
          this.props.onNativeMessageReceived(eventDataObj);
        }

        if (eventDataObj.type && eventDataObj.type.startsWith("RN_LOGOUT")) {
          this.props.logOutUser();
        }

        if (
          eventDataObj.type &&
          eventDataObj.type.startsWith("RN_NAVIGATE_TO") &&
          eventDataObj.payload &&
          eventDataObj.payload.redirectUrl
        ) {
          history.push(eventDataObj.payload.redirectUrl);
        }
      } catch (error) {
        // nothing
      }
    };

    if (isiOS)
      window.addEventListener("message", event => messageHandler(event), false);
    else
      document.addEventListener(
        "message",
        event => messageHandler(event),
        false
      );
  }

  render() {
    return null;
  }
}

NativeAppMessaging.propTypes = {
  onNativeMessageReceived: PropTypes.func.isRequired
};

export default withRouter(NativeAppMessaging);
