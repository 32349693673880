import { clearOfertas } from "../../ofertasForm/js/actions";
const PLAZOS_DEFAULT = [12, 18, 24, 36, 48];
export const PRESTAMOFORM_ISLOADING = "PRESTAMOFORM_ISLOADING";
export const PRESTAMOFORM_SUBMITFORM = "PRESTAMOFORM_SUBMITFORM";
export const PRESTAMOFORM_BLOCKEDFORM = "PRESTAMOFORM_BLOCKEDFORM";
export const PRESTAMOFORM_INIT = "PRESTAMOFORM_INIT";
export const PRESTAMOFORM_LOAD = "PRESTAMOFORM_LOAD";
export const PRESTAMOFORM_GETPLAZOS_INITED = "PRESTAMOFORM_GETPLAZOS_INITED";
export const PRESTAMOFORM_GETPLAZOS_COMPLETED =
  "PRESTAMOFORM_GETPLAZOS_COMPLETED";
export const PRESTAMOFORM_GETPLAZOS_FAILED = "PRESTAMOFORM_GETPLAZOS_FAILED";
export const PRESTAMOFORM_SELECTPLAZO = "PRESTAMOFORM_SELECTPLAZO";

export const prestamoFormIsLoading = loading => ({
  type: PRESTAMOFORM_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export const savePrestamo = formData => ({
  type: PRESTAMOFORM_SUBMITFORM,
  payload: {
    ...formData
  },
  analytics: {
    eventName: "solicitud-prestamo-guardar",
    eventData: {
      id: formData.Id,
      importe: formData.Importe,
      plazo: formData.Plazo
    }
  }
});

export const blockedForm = disabled => ({
  type: PRESTAMOFORM_BLOCKEDFORM,
  payload: {
    disabled: disabled
  }
});

export const prestamoFormInit = () => ({
  type: PRESTAMOFORM_INIT
});

export const prestamoFormLoad = data => ({
  type: PRESTAMOFORM_LOAD,
  payload: {
    data: data
  }
});

export const prestamoFormSelectPlazo = plazo => {
  return {
    type: PRESTAMOFORM_SELECTPLAZO,
    payload: {
      plazo: plazo
    }
  };
};

export const prestamoFormGetPlazosInited = () => ({
  type: PRESTAMOFORM_GETPLAZOS_INITED
});

export const prestamoFormGetPlazosFailed = error => ({
  type: PRESTAMOFORM_GETPLAZOS_FAILED
});

export const prestamoFormGetPlazosCompleted = data => ({
  type: PRESTAMOFORM_GETPLAZOS_COMPLETED,
  payload: { plazos: data }
});

export function prestamoFormGetPlazos(solicitudId) {
  return async function(dispatch, getState, services) {
    return new Promise(async function(resolve, reject) {
      dispatch(prestamoFormGetPlazosInited());
      await services.api
        .getEstadoCalificacion(solicitudId)
        .then(calificacion => {
          if (calificacion.realizada) {
            services.api
              .getPlazosPrestamo(solicitudId)
              .then(plazos => {
                if (!plazos || plazos.length === 0) plazos = PLAZOS_DEFAULT;
                dispatch(prestamoFormGetPlazosCompleted(plazos));
                resolve();
              })
              .catch(error => {
                console.error(error);
                dispatch(services.errorHandler.checkErrorAction(error));
                services.errorHandler.logRemoteError(
                  error,
                  prestamoFormGetPlazos,
                  [solicitudId],
                  getState()
                );
                dispatch(prestamoFormGetPlazosFailed(error));
                reject();
              });
          } else {
            dispatch(prestamoFormGetPlazosCompleted(PLAZOS_DEFAULT));
          }
        });
    });
  };
}

export function submitForm(formData) {
  return function(dispatch, getState, services) {
    return new Promise(function(resolve, reject) {
      dispatch(prestamoFormIsLoading(true));

      const prestamoData = {
        Id: formData.Id,
        Importe: formData.Importe,
        Plazo: formData.Plazo
      };

      services.api
        .postSolicitudImporte(prestamoData)
        .then(() => {
          dispatch(clearOfertas());
          dispatch(blockedForm(true));
          dispatch(prestamoFormIsLoading(false));
          dispatch(savePrestamo(formData));
          resolve();
        })
        .catch(error => {
          services.sendMessage(error.message, "error");
          dispatch(prestamoFormIsLoading(false));
          reject();
        });
    });
  };
}
