let createGetAceptacion = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError,
  localRepository
}) => IdApp => {
  let request = buildRequestWithAuth("GET");
  const {
    app,
    version,
    idUsuario
  } = localRepository.terminosYCondiciones.get();
  const { user } = localRepository.auth.get();
  const { appName, terminosYCondicionesVersion } = environment;
  let mostrarTerminos = !(
    app === appName &&
    idUsuario === user.sub &&
    version === terminosYCondicionesVersion
  );
  if (mostrarTerminos) {
    return fetch(
      `${environment.authUrl}/controldeusuarios/terminos/aceptacion/${IdApp}`,
      request
    )
      .then(errorUnlessOk)
      .then(response => {
        return response.json();
      })
      .catch(error => {
        let resultError = parseError(error, "createGetAceptacion");
        console.error(resultError);
        throw resultError;
      });
  } else {
    return {
      app: appName,
      version: terminosYCondicionesVersion,
      fechaDesde: "",
      fechaFirma: ""
    };
  }
};

let createPostAceptarTerminos = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => data => {
  let request = buildRequestWithAuth("POST", data);

  return fetch(
    `${environment.authUrl}/controldeusuarios/terminos/aceptacion`,
    request
  )
    .then(errorUnlessOk)
    .catch(error => {
      let resultError = parseError(error, "createPostAceptarTerminos");
      console.error(resultError);
      throw resultError;
    });
};

export { createGetAceptacion, createPostAceptarTerminos };
