export const ACTIVIDADFORM_ISLOADING = "ACTIVIDADFORM_ISLOADING";
export const ACTIVIDADFORM_SUBMITFORM = "ACTIVIDADFORM_SUBMITFORM";
export const ACTIVIDADFORM_BLOCKEDFORM = "ACTIVIDADFORM_BLOCKEDFORM";
export const ACTIVIDADFORM_INIT = "ACTIVIDADFORM_INIT";
export const ACTIVIDADFORM_LOAD = "ACTIVIDADFORM_LOAD";
export const ACTIVIDADFORM_SELECTACTIVIDAD = "ACTIVIDADFORM_SELECTACTIVIDAD";
export const ACTIVIDADFORM_SELECTRUBRO = "ACTIVIDADFORM_SELECTRUBRO";
export const ACTIVIDADFORM_SELECTANTIGUEDAD = "ACTIVIDADFORM_SELECTANTIGUEDAD";
export const ACTIVIDADFORM_SELECTACTIVIDADCONYUGE =
  "ACTIVIDADFORM_SELECTACTIVIDADCONYUGE";

export const actividadFormIsLoading = loading => ({
  type: ACTIVIDADFORM_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export const selectActividad = selected => {
  return {
    type: ACTIVIDADFORM_SELECTACTIVIDAD,
    payload: {
      actividadDeclarada: selected
    }
  };
};

export const selectRubro = selected => {
  return {
    type: ACTIVIDADFORM_SELECTRUBRO,
    payload: {
      rubro: selected
    }
  };
};

export const selectAntiguedad = selected => {
  return {
    type: ACTIVIDADFORM_SELECTANTIGUEDAD,
    payload: {
      antiguedad: selected
    }
  };
};

export const selectActividadConyuge = selected => {
  return {
    type: ACTIVIDADFORM_SELECTACTIVIDADCONYUGE,
    payload: {
      actividadConyuge: selected
    }
  };
};

export const submitActividad = formData => ({
  type: ACTIVIDADFORM_SUBMITFORM,
  payload: {
    ...formData
  },
  analytics: {
    eventName: "solicitud-actividad-guardar",
    eventData: {
      Id: formData.Id,
      Ingreso: formData.Ingresos,
      ActividadDeclarada: formData.ActividadDeclarada,
      Antiguedad: formData.Antiguedad,
      RubroEmpleoPublico: formData.RubroEmpleoPublico,
      ActividadDeclaradaConyuge: formData.ActividadDeclaradaConyuge,
      IngresoConyuge: formData.IngresosConyuge
    }
  }
});

export const blockedForm = disabled => ({
  type: ACTIVIDADFORM_BLOCKEDFORM,
  payload: {
    disabled: disabled
  }
});

export const actividadFormInit = () => ({
  type: ACTIVIDADFORM_INIT
});

export const actividadFormLoad = data => ({
  type: ACTIVIDADFORM_LOAD,
  payload: {
    data: data
  }
});

export function submitForm(
  solicitudId,
  ingresos,
  actividadDeclarada,
  rubro,
  antiguedad,
  actividadConyuge,
  ingresosConyuge
) {
  return function(dispatch, getState, services) {
    return new Promise(function(resolve, reject) {
      dispatch(actividadFormIsLoading(true));

      const ingresosData = {
        Id: solicitudId,
        Ingreso: ingresos,
        ActividadDeclarada: actividadDeclarada,
        RubroEmpleoPublico: rubro,
        Antiguedad: antiguedad,
        ActividadDeclaradaConyuge: actividadConyuge,
        IngresoConyuge: ingresosConyuge
      };

      services.api
        .postSolicitudIngresos(ingresosData)
        .then(() => {
          dispatch(blockedForm(true));
          dispatch(actividadFormIsLoading(false));
          dispatch(submitActividad(ingresosData));
          resolve();
        })
        .catch(error => {
          services.sendMessage(error.message, "error");
          dispatch(actividadFormIsLoading(false));
          reject();
        });
    });
  };
}
