import {
  SOLICITUD_SEGUROS_PEDIDO_COTIZACION_INITED,
  SOLICITUD_SEGUROS_PEDIDO_COTIZACION_COMPLETED,
  SOLICITUD_SEGUROS_PEDIDO_COTIZACION_FAILED,
  SOLICITUD_SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED,
  SOLICITUD_SEGUROS_COTIZACIONES_CLEAR,
  SOLICITUD_SEGUROS_COTIZACIONES_SEGURO_SELECT,
  SEGUROFORM_INIT,
  SEGUROFORM_LOAD,
  SOLICITUD_SEGUROS_SUBMIT_FORM_COMPLETED,
  SOLICITUD_SEGUROS_SUBMIT_FORM_FAILED,
  SOLICITUD_SEGUROS_SUBMIT_FORM_INITED
} from "./actions";

const initialState = {
  isLoading: false,
  seguros: null,
  cotizacionesCounter: 0,
  cotizacionId: null,
  seguroSelected: null,
  finalizado: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SOLICITUD_SEGUROS_COTIZACIONES_CLEAR:
    case SEGUROFORM_INIT:
      return { ...initialState };
    case SOLICITUD_SEGUROS_PEDIDO_COTIZACION_INITED:
      return {
        ...state,
        cotizacionId: null,
        seguros: null,
        cotizacionesCounter: 0,
        seguroSelected: null,
        isLoading: true
      };
    case SOLICITUD_SEGUROS_PEDIDO_COTIZACION_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case SOLICITUD_SEGUROS_PEDIDO_COTIZACION_COMPLETED:
      return {
        ...state,
        cotizacionId: action.payload.cotizacionId
      };
    case SOLICITUD_SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED:
      return {
        ...state,
        isLoading: false,
        cotizacionesCounter: state.cotizacionesCounter + 1,
        seguros: action.payload.seguros
      };
    case SOLICITUD_SEGUROS_COTIZACIONES_SEGURO_SELECT:
      return {
        ...state,
        seguroSelected: { ...action.payload.seguroSelected },
        finalizado: false
      };
    case SEGUROFORM_LOAD:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    case SOLICITUD_SEGUROS_SUBMIT_FORM_INITED:
      return {
        ...state,
        isLoading: true,
        finalizado: true
      };
    case SOLICITUD_SEGUROS_SUBMIT_FORM_COMPLETED:
      return { ...state, isLoading: false, finalizado: true };
    case SOLICITUD_SEGUROS_SUBMIT_FORM_FAILED:
      return { ...state, isLoading: false, finalizado: false };
    default:
      return state;
  }
};

export default reducer;
