export const APP_NEEDREFRESH = "APP_NEEDREFRESH";
export const APP_FIREBASETOKENSENT = "APP_FIREBASETOKENSENT";

const initialState = {
  needRefresh: false,
  firebaseTokenSent: false
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_NEEDREFRESH:
      return {
        ...state,
        needRefresh: action.payload.needRefresh
      };
    case APP_FIREBASETOKENSENT:
      return {
        ...state,
        firebaseTokenSent: action.payload.firebaseTokenSent
      };
    default:
      return state;
  }
};

export default appReducer;
