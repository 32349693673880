import {
  RN_IS_ON_NATIVE_APP,
  RN_DEVICE_INFO,
  RN_SCAN_CEDULA,
  CLEAR_NATIVE_CEDULA,
  RN_SCAN_DNI,
  RN_NATIVE_CREDENTIALS
} from "./actions";
import localRepository from "../../../localRepository";

const initialState = {
  isOnNativeApp: localRepository.isOnNativeApp.get(),
  deviceInfo: null,
  dominio: null,
  motor: null,
  chasis: null,
  nombre: null,
  apellido: null,
  sexo: null,
  dni: null,
  fechaNacimiento: null,
  nativeCredentials: null
};

const nativeMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RN_IS_ON_NATIVE_APP:
      localRepository.isOnNativeApp.set(true);
      return {
        ...state,
        isOnNativeApp: true
      };
    case RN_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.payload.data
      };
    case RN_SCAN_CEDULA:
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_NATIVE_CEDULA:
      return {
        ...state,
        dominio: null,
        motor: null,
        chasis: null
      };
    case RN_SCAN_DNI:
      return {
        ...state,
        ...action.payload
      };
    case RN_NATIVE_CREDENTIALS:
      alert("Native credentials", action.payload.credentials);
      return {
        ...state,
        nativeCredentials: action.payload.credentials
      };
    default:
      return state;
  }
};

export default nativeMessagesReducer;
