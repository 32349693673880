import { createActions, handleActions } from "redux-actions";

export const {
  capturaAutoInited,
  capturaAutoFailed,
  capturaAutoCompleted,
  clearCapturaAuto
} = createActions(
  "CAPTURA_AUTO_INITED",
  "CAPTURA_AUTO_FAILED",
  "CAPTURA_AUTO_COMPLETED",
  "CLEAR_CAPTURA_AUTO"
);

export const capturaAuto = (key, stream) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(capturaAutoInited());

      const image = await fetch(stream);
      const blob = await image.blob();

      try {
        let response = await services.api.postCapturaAuto(key, blob);
        // let response = {
        //   results: [
        //     {
        //       plate: "AA547GV",
        //       box: {
        //         xmin: 153,
        //         ymin: 254,
        //         ymax: 303,
        //         xmax: 264
        //       }
        //     }
        //   ]
        // };
        console.log("Plate response", response);
        dispatch(capturaAutoCompleted(response));
      } catch (e) {
        console.log(e);
        dispatch(capturaAutoFailed());
      }
    } catch (error) {
      console.error(error);

      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(capturaAutoFailed(error));
    }
  };
};

const capturarAutoReducer = handleActions(
  {
    [capturaAutoInited]: state => {
      return { ...state, plate: "", isLoading: true, error: false };
    },
    [capturaAutoCompleted]: (state, action) => {
      return {
        ...state,
        plate:
          action.payload.results.length > 0
            ? action.payload.results[0].plate
            : null,
        box:
          action.payload.results.length > 0
            ? action.payload.results[0].box
            : null,
        isLoading: false,
        error: false
      };
    },
    [capturaAutoFailed]: (state, action) => {
      return {
        ...state,
        plate: null,
        box: null,
        isLoading: false,
        error: true
      };
    },
    [clearCapturaAuto]: state => {
      return {
        ...state,
        plate: null,
        box: null,
        isLoading: false,
        error: false
      };
    }
  },
  {
    plate: null,
    box: null
  }
);

export default capturarAutoReducer;
