import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FeatureFlagListener from "./FeatureFlagListener";
import { changeFlag } from "./js/actions";

const mapStateToProps = state => {
  return {
    user: state.session.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      changeFlag
    },
    dispatch
  );
};

const FeatureFlagListenerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureFlagListener);

export default FeatureFlagListenerContainer;
