import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logOutUser } from "./js/actions";

import Logout from "./Logout";

const mapStateToProps = state => {
  return {
    // nothing
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logOutUser
    },
    dispatch
  );
};

const LogoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);

export default LogoutContainer;
