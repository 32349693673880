export const VEHICULOS_MARCAS_INITED = "VEHICULOS_MARCAS_INITED";
export const VEHICULOS_MARCAS_COMPLETED = "VEHICULOS_MARCAS_COMPLETED";
export const VEHICULOS_MARCAS_FAILED = "VEHICULOS_MARCAS_FAILED";

export const fetchVehiculosMarcas = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchVehiculosMarcasInited());

      const response = await services.api.getInfoautoMarcas();

      dispatch(fetchVehiculosMarcasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchVehiculosMarcasFailed(error));

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

const fetchVehiculosMarcasInited = () => {
  return {
    type: VEHICULOS_MARCAS_INITED
  };
};

const fetchVehiculosMarcasCompleted = response => {
  let marcas = [];

  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0
  ) {
    marcas = response.map(x => ({ value: x, label: x }));
  }

  return {
    type: VEHICULOS_MARCAS_COMPLETED,
    payload: {
      marcas
    }
  };
};

const fetchVehiculosMarcasFailed = error => {
  return {
    type: VEHICULOS_MARCAS_FAILED,
    payload: {
      error
    }
  };
};
