import {
  identidadFormInit,
  identidadFormLoad
} from "../../identidadForm/actions";
import {
  actividadFormInit,
  actividadFormLoad
} from "../../actividadForm/js/actions";
import { canalFormInit, canalFormLoad } from "../../canalForm/actions";
import {
  direccionFormLoad,
  direccionFormInit
} from "../../direccionForm/js/actions";
import {
  contactoFormInit,
  contactoFormLoad
} from "../../contactoForm/js/actions";
import { autoFormLoad, autoFormInit } from "../../autoForm/actions";
import {
  prestamoFormLoad,
  prestamoFormInit
} from "../../prestamoForm/js/actions";
import {
  ofertasFormLoad,
  ofertasFormInit,
  clearOfertas
} from "../../ofertasForm/js/actions";
import { seguroFormLoad, seguroFormInit } from "../../seguroForm/js/actions";
import {
  resultadoFormLoad,
  resultadoFormInit
} from "../../resultadoForm/js/actions";

export const SOLICITUDCREDITO_CHANGEID = "SOLICITUDCREDITO_CHANGEID";
export const SOLICITUDCREDITO_INIT = "SOLICITUDCREDITO_INIT";
export const SOLICITUDCREDITO_ISLOADING = "SOLICITUDCREDITO_ISLOADING";
export const SOLICITUDCREDITO_LOAD = "SOLICITUDCREDITO_LOAD";
export const SOLICITUDCREDITO_FINISH = "SOLICITUDCREDITO_FINISH";
export const SOLICITUDCREDITO_ACTIVEFORMCHANGED =
  "SOLICITUDCREDITO_ACTIVEFORMCHANGED";
export const SOLICITUDCREDITO_FINISHCOMPONENT =
  "SOLICITUDCREDITO_FINISHCOMPONENT";
export const SOLICITUDCREDITO_LOADDATA = "SOLICITUDCREDITO_LOADDATA";

export const solicitudInit = () => ({
  type: SOLICITUDCREDITO_INIT,
  payload: {}
});

export function loadSolicitud() {
  return (dispatch, getState, services) => {
    const localState = services.localStorage.solicitud.get();
    dispatch(canalFormLoad(localState.canalForm));
    dispatch(identidadFormLoad(localState.identidadForm));
    dispatch(actividadFormLoad(localState.actividadForm));
    dispatch(direccionFormLoad(localState.direccionForm));
    dispatch(contactoFormLoad(localState.contactoForm));
    dispatch(autoFormLoad(localState.autoForm));
    dispatch(prestamoFormLoad(localState.prestamoForm));
    dispatch(ofertasFormLoad(localState.ofertasForm));
    dispatch(seguroFormLoad(localState.seguroForm));
    dispatch(resultadoFormLoad(localState.resultadoForm));
    dispatch(solicitudCreditoUILoad(localState.solicitudCreditoUI));
  };
}

export const activeFormChanged = form => ({
  type: SOLICITUDCREDITO_ACTIVEFORMCHANGED,
  payload: { form: form }
});

export function changeActiveForm(form) {
  return function(dispatch, getState, services) {
    return new Promise(function(resolve, reject) {
      dispatch(activeFormChanged(form));
      let state = getState();
      let newState = {
        solicitudCreditoUI: state.solicitudCreditoUI,
        canalForm: state.canalForm,
        identidadForm: state.identidadForm,
        actividadForm: state.actividadForm,
        direccionForm: state.direccionForm,
        contactoForm: state.contactoForm,
        autoForm: state.autoForm,
        prestamoForm: state.prestamoForm,
        ofertasForm: state.ofertasForm,
        seguroForm: state.seguroForm,
        resultadoForm: state.resultadoForm
      };
      newState.createdAt = Date.now();
      services.localStorage.solicitud.set(newState);
      resolve();
    });
  };
}

export const finishSolicitud = finished => ({
  type: SOLICITUDCREDITO_FINISH,
  payload: {
    finished
  }
});

export function setSolicitudSequenceFinish(finished) {
  return (dispatch, getState, services) => {
    dispatch(finishSolicitud(finished));
    services.localStorage.lastSolicitud.set(
      services.localStorage.solicitud.get()
    );
    services.localStorage.solicitud.clear();
  };
}

export function resetSolicitud() {
  return function(dispatch, getState, services) {
    dispatch(solicitudInit());
    dispatch(canalFormInit());
    dispatch(identidadFormInit());
    dispatch(actividadFormInit());
    dispatch(direccionFormInit());
    dispatch(contactoFormInit());
    dispatch(autoFormInit());
    dispatch(prestamoFormInit());
    dispatch(ofertasFormInit());
    dispatch(seguroFormInit());
    dispatch(resultadoFormInit());
    dispatch(clearOfertas());
  };
}

export function discardSolicitud() {
  return function(dispatch, getState, services) {
    services.localStorage.solicitud.clear();
  };
}

export const changeSolicitudId = solicitudId => ({
  type: SOLICITUDCREDITO_CHANGEID,
  payload: {
    solicitudId: solicitudId
  }
});

export const setFinishComponent = (componentName, metadata) => ({
  type: SOLICITUDCREDITO_FINISHCOMPONENT,
  payload: {
    componentName,
    metadata
  }
});

export const solicitudCreditoIsLoading = isLoading => ({
  type: SOLICITUDCREDITO_ISLOADING,
  payload: {
    isLoading: isLoading
  }
});

export const loadSolicitudCredito = solicitudCredito => ({
  type: SOLICITUDCREDITO_LOAD,
  payload: {
    ...solicitudCredito
  }
});

export const solicitudCreditoUILoad = data => ({
  type: SOLICITUDCREDITO_LOADDATA,
  payload: {
    data: data
  }
});
