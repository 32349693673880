import { Component } from "react";
import PropTypes from "prop-types";
import { initialize as ldClientInitialize } from "ldclient-js";
import environment from "../../../environment";
import uuidv4 from "uuid/v4";

class FeatureFlagListener extends Component {
  componentDidMount() {
    const user = this.getCurrentUser();

    this.client = ldClientInitialize(environment.launchDarkly.sdkKey, user);

    this.client.waitForInitialization().then(() => {
      let featureFlags = { ...this.client.allFlags() };

      const initialFlags = Object.keys(featureFlags).reduce(
        (result, current) => {
          const initialValue = this.client.variation(
            current,
            featureFlags[current]
          );
          return { ...result, [current]: initialValue };
        },
        {}
      );

      this.props.changeFlag(initialFlags);

      this.client.on(`change`, flagsChangedEvent => {
        const flagsChanged = Object.keys(flagsChangedEvent).reduce(
          (result, current) => {
            return {
              ...result,
              [current]: flagsChangedEvent[current].current
            };
          },
          {}
        );

        this.props.changeFlag(flagsChanged);
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user && this.client) {
      const user = this.getCurrentUser();
      console.log("user", user);

      this.client.identify(user).then(newFlags => {
        console.log("newflags", newFlags);
        this.props.changeFlag(newFlags);
      });
    }
  }

  getCurrentUser = () => {
    let user = {
      key: uuidv4(),
      name: "no autenticado"
    };

    if (this.props.user) {
      user = {
        key: this.props.user.email,
        name: this.props.user.preferred_username,
        email: this.props.user.email
      };
    }

    return user;
  };

  render() {
    return null;
  }
}

FeatureFlagListener.propTypes = {
  changeFlag: PropTypes.func.isRequired,
  user: PropTypes.any
};

export default FeatureFlagListener;
