export const SEGUROS_PEDIDO_COTIZACION_INITED =
  "SEGUROS_PEDIDO_COTIZACION_INITED";
export const SEGUROS_PEDIDO_COTIZACION_COMPLETED =
  "SEGUROS_PEDIDO_COTIZACION_COMPLETED";
export const SEGUROS_PEDIDO_COTIZACION_FAILED =
  "SEGUROS_PEDIDO_COTIZACION_FAILED";

export const SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED =
  "SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED";

export const SEGUROS_COTIZACIONES_CLEAR = "SEGUROS_COTIZACIONES_CLEAR";
export const SEGUROS_COTIZACIONES_SEGURO_SELECT =
  "SEGUROS_COTIZACIONES_SEGURO_SELECT";

export const SEGUROS_QUIERO_ESTA_POLIZA = "SEGUROS_QUIERO_ESTA_POLIZA";
export const SEGUROS_QUIERO_QUE_ME_CONTACTEN =
  "SEGUROS_QUIERO_QUE_ME_CONTACTEN";

export const fetchPedidoCotizacionSeguros = (data, successCallback) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchPedidoCotizacionInited());

      const negocio = "MercadoAbierto";
      const periodo = "Anual";

      const { anio, modelo, codigoPostal, uso, gnc, formaPago } = data;

      const responsePedidoCotizacion = await services.api.postSegurosCotizar({
        AnioVehiculo: anio,
        ModeloVehiculo: modelo,
        CodigoPostal: codigoPostal,
        UsoDelVehiculo: uso,
        TieneGNC: gnc,
        FormaPago: formaPago,
        Negocio: negocio,
        Periodo: periodo
      });

      dispatch(fetchPedidoCotizacionCompleted(responsePedidoCotizacion));

      if (responsePedidoCotizacion && responsePedidoCotizacion.cotizacionId)
        dispatch(
          fetchCotizacionesCompaniasSeguros(
            responsePedidoCotizacion.cotizacionId,
            successCallback
          )
        );
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchPedidoCotizacionSeguros,
        [data, successCallback],
        getState()
      );
      dispatch(fetchPedidoCotizacionFailed(error));

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

export const fetchCotizacionesCompaniasSeguros = (
  cotizacionId,
  successCallback
) => {
  return async (dispatch, getState, services) => {
    try {
      const cotizacionesResult = await services.api.getSegurosCotizaciones(
        cotizacionId
      );

      //const cotizacionesResult = segurosCotizacionMock;

      dispatch(fetchCotizacionesCompaniasSegurosCompleted(cotizacionesResult));

      if (successCallback) successCallback();
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchCotizacionesCompaniasSeguros,
        [cotizacionId, successCallback],
        getState()
      );

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

const fetchCotizacionesCompaniasSegurosCompleted = response => {
  let seguros = { cotizacionId: null, companias: [] };

  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0 &&
    response.companias &&
    response.companias.length > 0
  ) {
    seguros = response;
  }

  return {
    type: SEGUROS_COTIZACIONES_COMPANIAS_COMPLETED,
    payload: {
      seguros
    }
  };
};

const fetchPedidoCotizacionInited = () => {
  return {
    type: SEGUROS_PEDIDO_COTIZACION_INITED
  };
};

const fetchPedidoCotizacionCompleted = response => {
  let cotizacionId = null;

  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0 &&
    response.cotizacionId
  ) {
    cotizacionId = response.cotizacionId;
  }

  return {
    type: SEGUROS_PEDIDO_COTIZACION_COMPLETED,
    payload: {
      cotizacionId
    },
    analytics: {
      eventName: "cotizador-seguros-pedido-cotizacion",
      eventData: {}
    }
  };
};

const fetchPedidoCotizacionFailed = error => {
  return {
    type: SEGUROS_PEDIDO_COTIZACION_FAILED,
    payload: {
      error
    }
  };
};

export const sendQuieroQueMeContacten = ({
  telefono,
  telefonoMovil,
  nombre,
  email,
  cotizacionId,
  cotizacionPlanId
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch({
        type: SEGUROS_QUIERO_QUE_ME_CONTACTEN,
        analytics: {
          eventName: "cotizador-seguros-quiero-que-me-contacten",
          eventData: {
            telefono,
            telefonoMovil,
            nombre,
            email,
            cotizacionId,
            cotizacionPlanId
          }
        }
      });

      await services.api.postSegurosQuieroQueMeContacten({
        Nombre: nombre,
        Celular: telefono,
        Email: email,
        IdCotizacion: cotizacionId,
        CotizacionPlanId: cotizacionPlanId
      });
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        sendQuieroQueMeContacten,
        [
          {
            telefono,
            telefonoMovil,
            nombre,
            email,
            cotizacionId,
            cotizacionPlanId
          }
        ],
        getState()
      );

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

export const sendQuieroEstaPoliza = data => {
  return async (dispatch, getState, services) => {
    try {
      const { cotizacionId, cotizacionPlanId } = data;

      dispatch({
        type: SEGUROS_QUIERO_ESTA_POLIZA,
        analytics: {
          eventName: "cotizador-seguros-quiero-esta-poliza",
          eventData: {
            ...data
          }
        }
      });

      await services.api.postSegurosQuieroEstaPoliza({
        IdCotizacion: cotizacionId,
        CotizacionPlanId: cotizacionPlanId
      });
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        sendQuieroEstaPoliza,
        [data],
        getState()
      );

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

export const clearCotizacionesSeguros = () => {
  return {
    type: SEGUROS_COTIZACIONES_CLEAR,
    analytics: {
      eventName: "cotizador-seguros-nueva-solicitud",
      eventData: {}
    }
  };
};

export const seguroSelect = seguroSelected => {
  return {
    type: SEGUROS_COTIZACIONES_SEGURO_SELECT,
    payload: {
      seguroSelected
    },
    analytics: {
      eventName: "cotizador-seguros-cotizado-seleccionado",
      eventData: { ...seguroSelected }
    }
  };
};
