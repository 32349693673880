import { createAction, handleActions } from "redux-actions";
import localRepository from "../../localRepository";
import environment from "../../environment";

export const checkTerminosYCondicionesInited = createAction(
  "CHECK_TERMINOS_Y_CONDICIONES_INITED"
);
export const checkTerminosYCondicionesCompleted = createAction(
  "CHECK_TERMINOS_Y_CONDICIONES_COMPLETED"
);
export const checkTerminosYCondicionesFailed = createAction(
  "CHECK_TERMINOS_Y_CONDICIONES_FAILED"
);
export const aceptarTerminosInited = createAction("ACEPTAR_TERMINOS_INITED");
export const aceptarTerminosCompleted = createAction(
  "ACEPTAR_TERMINOS_COMPLETED"
);
export const aceptarTerminosFailed = createAction("ACEPTAR_TERMINOS_FAILED");

export function checkTerminosYCondiciones(user) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(checkTerminosYCondicionesInited());
      const mostrarTerminos = await getMostrarTerminos(user, services);
      dispatch(checkTerminosYCondicionesCompleted({ mostrarTerminos }));
    } catch (error) {
      console.error(error);
      dispatch(services.errorHandler.checkErrorAction(error));
      services.errorHandler.logRemoteError(
        error,
        checkTerminosYCondiciones,
        [user],
        getState()
      );
      dispatch(checkTerminosYCondicionesFailed(error));
    }
  };
}

export const getMostrarTerminos = async (user, services) => {
  try {
    const { appName } = environment;
    let response = await services.api.getAceptacion(appName);
    let mostrarTerminos = !(
      response.version === environment.terminosYCondicionesVersion
    );
    if (response.app && response.version)
      localRepository.terminosYCondiciones.set({
        app: response.app,
        version: response.version,
        idUsuario: user.sub,
        name: user.preferred_username
      });

    return mostrarTerminos;
  } catch (error) {
    throw error;
  }
};

export function aceptarTerminos(idUsuario, userName) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(aceptarTerminosInited());
      const data = {
        app: environment.appName,
        version: environment.terminosYCondicionesVersion
      };
      await services.api.postAceptarTerminos(data);

      localRepository.terminosYCondiciones.set({
        app: environment.appName,
        version: environment.terminosYCondicionesVersion,
        idUsuario: idUsuario,
        name: userName
      });
      dispatch(aceptarTerminosCompleted({ mostrarTerminos: false }));
    } catch (error) {
      console.error(error);
      localRepository.terminosYCondiciones.set({
        app: environment.appName,
        version: environment.terminosYCondicionesVersion,
        idUsuario: idUsuario,
        name: userName
      });
      services.errorHandler.logRemoteError(
        error,
        aceptarTerminos,
        [idUsuario],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(aceptarTerminosFailed(error));
    }
  };
}

const terminosYCondicionesReducer = handleActions(
  {
    [checkTerminosYCondicionesInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [checkTerminosYCondicionesCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        mostrarTerminos: action.payload.mostrarTerminos
      };
    },
    [checkTerminosYCondicionesFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true,
        mostrarTerminos: false
      };
    },
    [aceptarTerminosInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [aceptarTerminosCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        mostrarTerminos: action.payload.mostrarTerminos
      };
    },
    [aceptarTerminosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true,
        mostrarTerminos: false
      };
    }
  },
  {
    mostrarTerminos: false,
    isLoading: false,
    error: false
  }
);

export default terminosYCondicionesReducer;
