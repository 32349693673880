import fetchretry from "fetch-retry";

let createGetInfoautoMarcas = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => () => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/infoauto/marcas?format=json`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createGetInfoautoModelos = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (marca, anio) => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/infoauto/marcas/${encodeURI(
      marca
    )}/anios/${encodeURI(anio)}/modelos?format=json`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createGetInfoautoCodiaPrecioAbierto = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => codia => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/infoauto/modelos/${encodeURI(
      codia
    )}/cotizaciones?format=json`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createGetInfoautoCodiaPrecio = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => codia => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/infoauto/modelos/${encodeURI(
      codia
    )}/detalle?format=json`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createPostCapturaAutoAsync = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (key, image) => {
  let request = buildRequestWithAuth("GET");

  return fetch(`${environment.presignerUrl}/sign?key=autos/${key}.jpg`, request)
    .then(errorUnlessOk)
    .then(res => res.json())
    .then(json => {
      var xhr = new XMLHttpRequest();
      xhr.open("PUT", json.url);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.onload = () => {
        if (xhr.status === 200) {
          console.log("Image uploaded!");
        }
      };
      xhr.onerror = e => {
        console.log(e);
      };
      xhr.send(image);
    })
    .catch(err => {
      console.log(err);
    });
};

let createGetCapturaAutoResult = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => key => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.presignerUrl}/sign?key=patentes/${key}.json&verb=get`,
    request
  )
    .then(errorUnlessOk)
    .then(res => res.json())
    .then(json => {
      return fetchretry(json.url, {
        retryOn: [404],
        retries: 5,
        retryDelay: 1000
      })
        .then(function(response) {
          return response.text();
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

function postFile(url, formData, localRepository) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localRepository.auth.get().token
    );
    xhr.onload = function() {
      if (this.status >= 200 && this.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function() {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    xhr.send(formData);
  });
}

let createPostCapturaAuto = ({ environment, localRepository }) => (
  key,
  image
) => {
  const formData = new FormData();
  formData.append(key, image);

  return postFile(
    `${environment.originacionUrl}/autos/captura`,
    formData,
    localRepository
  );
};

export {
  createGetInfoautoMarcas,
  createGetInfoautoModelos,
  createGetInfoautoCodiaPrecio,
  createGetInfoautoCodiaPrecioAbierto,
  createPostCapturaAuto,
  createPostCapturaAutoAsync,
  createGetCapturaAutoResult
};
