import {
  LOG_IN_ISLOADING,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT,
  RECOVERY_PASSWORD_SUCCESS,
  LOG_IN_ISAUTHENTICATED,
  RECOVER_PASSWORD_BEGIN,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_SENDED_SUCCESSFULLY
} from "./actions";

const initialState = {
  logIn: false,
  error: null,
  isLoading: false,
  user: {}
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN_ISLOADING:
      return action.payload;
    case LOG_IN_SUCCESS:
      return action.payload;
    case LOG_IN_ERROR:
      return action.payload;
    case LOG_OUT:
      return action.payload;
    case RECOVER_PASSWORD_BEGIN:
      return action.payload;
    case RECOVER_PASSWORD_SENDED_SUCCESSFULLY:
      return action.payload;
    case RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        error: action.payload.error
      };
    case RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        error: action.payload.error
      };
    case LOG_IN_ISAUTHENTICATED:
      return {
        ...state,
        logIn: action.payload.logIn
      };
    default:
      return state;
  }
};

export default sessionReducer;
