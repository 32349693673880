import { connect } from "react-redux";
import { logOutUser } from "../user/js/actions";
import {
  toggleOpenMainMenu,
  toggleOpenNotificationBar,
  openProfileMenu,
  setTopBar
} from "./js/actions";

import TopBar from "./TopBar";

function mapStateToProps(state) {
  return {
    session: state.session,
    topBar: state.topBar
  };
}

const mapDispatchToProps = dispatch => {
  return {
    toggleOpenNotificationBar: open => {
      dispatch(toggleOpenNotificationBar(open));
    },
    toggleOpenMainMenu: open => {
      dispatch(toggleOpenMainMenu(open));
    },
    openProfileMenu: payload => {
      dispatch(openProfileMenu(payload));
    },
    logOutUser: () => {
      dispatch(logOutUser());
    },
    setTopBar: (title, backUrl, menuItem) => {
      dispatch(setTopBar(title, backUrl, menuItem));
    }
  };
};

const TopBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBar);

export default TopBarContainer;
