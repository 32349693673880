import { createAction, handleActions } from "redux-actions";
import localRepository from "../../localRepository";

export const canalFormLoad = createAction("CANALFORM_LOAD", data => ({ data }));

export const canalFormFetchInited = createAction("CANALFORM_FETCH_INITED");
export const canalFormFetchCompleted = createAction(
  "CANALFORM_FETCH_COMPLETED",
  canales => ({ canales })
);
export const canalFormFetchFailed = createAction("CANALFORM_FETCH_FAILED");

export const canalFormInit = createAction("CANALFORM_INIT");
export const canalFormClear = createAction("CANALFORM_CLEAR");

export const selectCanal = createAction(
  "CANALFORM_SUBMITFORM",
  selectedCanal => ({ selectedCanal }),
  selectedCanal => ({
    analytics: {
      eventName: "solicitud-iniciar-solicitud",
      eventData: {
        canal: selectedCanal
      }
    }
  })
);

export function fetchCanalesUsuario() {
  return async (dispatch, getState, services) => {
    try {
      dispatch(canalFormFetchInited());
      const response = await services.api.getCanales();
      // const response = [
      //   {
      //     key: 10333,
      //     value: "Agencia de Prueba"
      //   },
      //   {
      //     key: 10316,
      //     value: "Ariel Guerrero"
      //   }
      // ];
      if (response.length === 1) dispatch(selectCanal(response[0]));

      const canales =
        response && response.length > 0
          ? response.map(x => ({ value: x.key, label: x.value }))
          : [];

      dispatch(canalFormFetchCompleted(canales));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchCanalesUsuario,
        [],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(canalFormFetchFailed(error));
    }
  };
}

export function submitForm({ value, label }, onComplete) {
  return async (dispatch, getState, services) => {
    dispatch(selectCanal({ key: value, value: label }));
    if (onComplete) onComplete();
  };
}

const initialState = {
  solicitudId: "",
  isLoading: false,
  selectedCanal: { key: "", value: "" },
  canales: localRepository.canales.get()
};

const canalFormReducer = handleActions(
  {
    [canalFormLoad]: (state, action) => {
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    },
    [canalFormInit]: (state, action) => {
      return {
        ...initialState,
        canales: state.canales
      };
    },
    [canalFormClear]: (state, action) => {
      return {
        ...initialState,
        canales: []
      };
    },
    [canalFormFetchInited]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [canalFormFetchCompleted]: (state, action) => {
      const newArray = action.payload.canales.slice(0);
      localRepository.canales.set(newArray);
      return {
        ...state,
        canales: newArray,
        isLoading: false
      };
    },
    [canalFormFetchFailed]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [selectCanal]: (state, action) => {
      return {
        ...state,
        selectedCanal: {
          ...action.payload.selectedCanal
        }
      };
    }
  },
  { ...initialState }
);

export default canalFormReducer;
